import React from 'react'

export function TermsOfUseServicesHeb() {
    

    return (
        <div className='mb-36 mx-auto ' style={{maxWidth:'700px',paddingLeft:'16px',paddingRight:'16px'}}>
            <div className='mt-10'>
        <h1 className='text-2xl sm:text:3xl text-center mb-6 underline'>תנאי שימוש</h1>
        <p className='underline my-5 font-bold'>
        אנא קרא* בעיון את התנאים וההגבלות הללו (להלן: &quot;ההסכם&quot;) לפני יצירת חשבון משתמש

ו/או השימוש בשירותי ההשמה.
        </p >
        <p className='underline my-5 font-bold'>מבוא.</p>
<p>הסכם זה מסדיר את כל השימושים (1) באתר [Start2] [www.start2.co.il] (להלן:</p>
<p>&quot;האתר&quot;), הנמצא בבעלות ווי וואש טכנולוגיות בע&quot;מ, חברה הממוקמת ברח&apos; הגרא 34, תל</p>
<p>אביב, ישראל, והמאוגדת כדין לפי חוקי מדינת ישראל (להלן: &quot;החברה&quot; או &quot; Start2&quot;); (2)</p>
<p>האפליקציה/ות של החברה למכשירים ניידים שעליהן חל הסכם זה (להלן: &quot;האפליקציה&quot;); ו/או</p>
<p>(3) כל אחד מהשירותים המוצעים על ידי החברה הנגישים דרך האתר ו/או האפליקציה.</p>
<p className='underline my-5 font-bold'>1. הגדרות.</p>
<p>&quot;האפליקציה&quot; כהגדרתה בפתיח של הסכם זה.</p>
<p>&quot;הדין החל&quot; משמעתו כל החוקים והתקנות החלים בישראל.</p>
<p>&quot;הזמנת השמה&quot; משמעה בקשה לשירותים מקצועיים שהוגשה על ידי לקוח באמצעות</p>
<p>האפליקציה.</p>
<p>&quot;חשבון משתמש&quot; משמעו חשבון המשתמש בחברה המכיל את המידע האישי של</p>
<p>המשתמש לרבות שם מלא, כתובת, תאריך לידה, לאום, אישורי שהייה/עבודה</p>
<p>ממשלתיים, תמונה, קבלה/דחיה של הצעות לשירותים מקצועיים, דיווחי שעות של</p>
<p>שירותים מקצועיים שבוצעו בפועל, על ידי המשתמש, משוב של לקוחות ו&hellip;&hellip;&hellip;. (להלן</p>
<p>ביחד: &quot;המידע האישי&quot;)</p>
<p>&quot;לקוחות&quot; משמעם מסעדות, מועדונים, בתי קפה ו/או כל מוסדות אחרים המגישים מזון</p>
<p>ו/או משקאות, המשתמשים באפליקציה ומעוניינים לקבל שירותים מקצועיים</p>
<p>מהמשתמשים.</p>
<p>&quot;מדיניות הפרטיות&quot; משמעתה מדיניות הפרטיות של החברה, המצויה באתר ו/או</p>
<p>באפליקציה. מדיניות הפרטיות עשויה להיות מתוקנת ו/או מעודכנת על ידי החברה מעת</p>
<p>לעת.</p>
<p>&quot;משתמש&quot; או &quot;אתה&quot; משמעו כל אדם המשתמש באפליקציה על מנת לקבל הזמנות</p>
<p>השמה עבור ביצוע שירותים מקצועיים באפליקציה וכדי לקבל, לפי שיקול דעתו את כל</p>
<p>הזמנות ההשמה הנ&quot;ל, חלקן אף או אחת מהן ובהתאם- ולבצע שירותים מקצועיים</p>
<p>כאמור, בהתאם לתנאי ההצעות שהתקבלו על ידו באמצעות האפליקציה.</p>
<p>&quot;שירותי ההשמה&quot; משמעם הפלטפורמה, באמצעות האפליקציה או בכל אופן אחר,</p>
<p>המחברת בין לקוחות ומשתמשים לצורך ביצוע שירותים מקצועיים בהתאם להזמנות</p>
<p>השמה;</p>
<p>&quot;שירותים מקצועיים&quot; משמעם שירותי שטיפת הכלים (ו/או כל שירותים אחרים הקשורים</p>
<p>לפעילות במטבח) הניתנים על ידי משתמש ללקוחות בהתאם להזמנות השמה.</p>
<p className='underline my-5 font-bold'>2. התקשרות בהסכם זה וקבלת מדיניות הפרטיות.</p>
<p>על ידי שימוש ו/או רישום לאפליקציה, לרבות (אך לא רק) יצירת חשבון משתמש Start2</p>
<p>(כפי שמוגדר לעיל), בין אם באמצעות מכשיר נייד, אפליקציה במכשיר נייד או מחשב,</p>
<p>אתה מסכים ומסכים מרצון להיות מחויב ל: (1) הסכם זה; וכן (2) מדיניות הפרטיות של</p>
<p>החברה.</p>
<p>אם אינך מקבל, מסכים או מעוניין בכל דרך להיות מחויב להסכם זה ו/או למדיניות</p>
<p>הפרטיות, אל תיצור חשבון משתמש ו/או תשתמש באפליקציה ו/או בשירותי</p>
<p>ההשמה.</p>
<p>החברה רשאית לשנות ולעדכן הסכם זה מעת לעת. הגרסה המעודכנת ביותר של הסכם</p>
<p>זה היא זו הזמינה באתר/אפליקציה. המשך השימוש שלך באתר, באפליקציה או</p>
<p>בשירותי ההשמה ייחשב כהסכמה שלך לגרסה העדכנית ביותר של הסכם זה.</p>
<p className='underline my-5 font-bold'>3. זכאות.</p>
<p>3.1 כדי להשתמש בשירותי ההשמה, וכדי לפתוח ולתחזק חשבון משתמש, עליך</p>
<p>להיות מעל גיל 18.</p>
<p>3.2 על ידי שימוש באפליקציה ובשירותי ההשמה, אתה מצהיר ומתחייב בזאת כי:</p>
<p>3.2.1 יש לך את כל ההיתרים הדרושים לביצוע השירותים המקצועיים.</p>
<p>3.2.2 אינך סובל מכל מחלה ו/או מוגבלות אשר מונעים ממך לבצע את</p>
<p>השירותים המקצועיים ו/או מונעים ממך בדרך אחרת לעבוד בענף</p>
<p>המסעדנות והברים.</p>
<p>3.2.3 קראת בעיון את הסכם זה ואת מדיניות הפרטיות והבנת את הוראותיהם.</p>
<p>3.2.4 ניתנה לך האפשרות לקבל תרגום/הסבר ביחס להוראות הסכם זה</p>
<p>ומדיניות הפרטיות בשפת אימך.</p>
<p>3.2.5 מעולם לא הורשעת בעבירה פלילית מסוג פשע ו/או בעבירת מין או</p>
<p>אלימות, וכי אינך נדרש להירשם כעבריין מין על פי דין.</p>
<p>3.2.6 ערכת הסכם עבודה עם _______________ (להלן: &quot;המעסיק הישיר&quot;),</p>
<p>והסכם כזה הוא בתוקף ובתוקף.</p>
<p>3.2.7 אתה תציית, בכל עת, להוראות הסכם זה, למדיניות הפרטיות ולדין החל.</p>
<p>3.2.8 אתה תודיע מיידית לחברה אם אחד מהתנאים המפורטים בסעיפים</p>
<p>3.2.1 -3.2.7 לעיל אינו מתקיים עוד.</p>
<p className='underline my-5 font-bold'>4. שימושך בחשבון Start2.</p>
<p>4.1 אתה האחראי הבלעדי לשמירה על פרטי ההזדהות שבהם אתה משתמש כדי</p>
<p>להירשם לחשבון ה- Start2שלך, ואתה האחראי הבלעדי לכל הפעילויות</p>
<p>המתרחשות במסגרת חשבון זה. לכן, מומלץ לשמור את נתוני הגישה שלך</p>
<p>הקשורים לחשבון ה- Start2שלך בסודיות מוחלטת.</p>
<p>4.2 חשבון ה- Start2שלך הוא אישי ואינו ניתן להעברה. אינך רשאי לחלוק הזמנות</p>
<p>השמה עם כל אדם אחר ואינך רשאי לאפשר לאף אדם אחר להשתמש בחשבון ה-</p>
<p>Start2שלך.</p>
<p className='underline my-5 font-bold'>5. שירותי ההשמה.</p>
<p>5.1 האפליקציה מספקת אמצעי לאפשר ללקוחות המבקשים שירותים מקצועיים (בין</p>
<p>אם על בסיס דחוף, חד פעמי או אחר) להיות מחוברים למשתמשים ולהיפך -</p>
<p>בהתבסס על המיקום הגיאוגרפי של הלקוחות והמשתמשים ועל בסיס זמינות</p>
<p>המשתמשים. החברה אינה מספקת שירותי שטיפת כלים ו/או ניקיון ואינה שלוח או</p>
<p>שותף של הלקוחות, ולא שלוח, שותף ו/או מעסיק של המשתמש. החברה הינה</p>
<p>ספקית שירותים טכנולוגית המשתמשת בפלטפורמה אלקטרונית למתן שירותי</p>
<p>ההשמה.</p>
<p>5.2 המשתמשים צריכים להוריד ולהתקין את האפליקציה במכשיר הנייד שלהם והם</p>
<p>יהיו אחראים לכל העלויות הכרוכות במכשיר הנייד הזה, לרבות (אך לא רק), כל</p>
<p>דמי שימוש בנתונים או שיחות.</p>
<p>5.3 המשתמשים חופשיים להשתמש באפליקציה, לקבל, להתעלם ו/או לדחות הזמנות</p>
<p>ביצוע בכל עת ואינם נדרשים לקבל מספר מינימלי של הזמנות השמה ואינם</p>
<p>מוגבלים במספר מירבי של הזמנות השמה, בכפוף להוראות סעיף 8.2 (ד) להלן,</p>
<p>למעט לכך שהמשתמשים חייבים לוודא שהם עומדים בכל הדרישות הרגולטוריות</p>
<p>המוטלות עליהם כספקי השירותים המקצועיים, במיוחד לגבי תקופות מנוחה.</p>
<p>5.4 על אף האמור בסעיף 5.1, החברה אינה מתחייבת שמשתמש כלשהו יקבל מספר</p>
<p>מינימלי של הזמנות השמה (ו/או הזמנות ביצוע בכלל), ואינה מבטיחה זמינות ו/או</p>
<p>שימוש ללא הפרעה או שגיאות באפליקציה. החברה, לפי שיקול דעתה הבלעדי,</p>
<p>רשאית לתעדף הזמנות השמה ו/או משתמשים כראות עיניה. החברה לא תישא</p>
<p>באחריות לכל נזק, הפסד, תביעות, עלויות או הוצאות הנובעות או כתוצאה מזמן</p>
<p>השבתה מתוכנן או לא מתוכנן, חוסר זמינות או איטיות.</p>
<p>5.5 שום דבר הכלול בתנאים אלה לא יתפרש כיוצר מערכת יחסים כלשהי של עובד-</p>
<p>מעסיק בין החברה למשתמש. כמו כן, החברה לא תישא באחריות כלפי</p>
<p>המשתמש, והמשתמש מוותר בזאת באופן בלתי הפיך על כל טענה כלפי החברה</p>
<p>ו/או הדירקטורים, נושאי המשרה ו/או עובדיה בקשר עם מתן שירותים מקצועיים</p>
<p>על ידי המשתמש, עם מעשים או מחדלים של הלקוחות ו/או עם כל מעשה או</p>
<p>מחדל של המעסיק הישיר.</p>
<p>5.6 שום דבר בהסכם זה לא יתפרש כיוצר יחסי שליחות בין המשתמש לבין החברה.</p>
<p>למשתמש לא תהיה כל זכות או סמכות כלשהי להתקשר בשם החברה או לחייב</p>
<p>את החברה בכל צורה שהיא ביחס לצדדים שלישיים, אלא אם כן הוסמך לכך</p>
<p>במפורש ובכתב.</p>
<p>5.7 אתה האחראי הבלעדי לאינטראקציות שלך עם הלקוחות. אתה מבין שהחברה</p>
<p>לא מבצעת כל בדיקת רקע של הלקוחות, וגם לא מתחייבת ולא מציגה מצגים</p>
<p>לגבי הלקוחות ו/או סביבת העבודה של הלקוחות.</p>
<p className='underline my-5 font-bold'>6. השירותים המקצועיים.</p>
<p>6.1 המשתמש מאשר שהמכשיר הנייד שלו חייב לספק את המיקום הגיאוגרפי שלו על</p>
<p>מנת לאפשר לו לקבל הזמנות השמה המבוססות על מיקומו. המשתמש מאשר</p>
<p>ומסכים שמידע המיקום הגיאוגרפי שלו יהיה נגיש על ידי האפליקציה וכאשר הוא</p>
<p>מחובר מיקומו יוצג לחברה וללקוחות.</p>
<p>6.2 המשתמש מאשר ומסכים כי באחריותו הבלעדית לדווח על הגעה למתחם הלקוח</p>
<p>ועל השלמת השירותים המקצועיים דרך האפליקציה, וכי אי ביצוע זה עלול לגרום</p>
<p>לאי תשלום כל תשלום המגיע למשתמש עבור שירותים מקצועיים אלה.</p>
<p>6.3 במקרה בו תחלה בין מועד אישור הזמנת השמה לבין מועד ביצוע ההזמנה, עליך</p>
<p>להודיע זאת מיידית לחברה וללקוח באמצעות האפליקציה.</p>
<p>6.4 המשתמש מתחייב כי יבצע את השירותים המקצועיים בצורה קפדנית ומקצועית,</p>
<p>יגיע למתחם הלקוח במועד שנקבע בהזמנת הביצוע, ישתף פעולה עם צוות</p>
<p>הלקוח וימלא אחר הוראות הלקוח.</p>
<p>6.5 אתה תשפה את החברה והלקוחות מפני כל חבויות, עלויות, הוצאות, נזקים</p>
<p>והפסדים (לרבות אך לא רק נזקים ישירים, עקיפים או תוצאתיים, אובדן רווח,</p>
<p>אובדן מוניטין וכל ריבית, קנסות, עלויות משפטיות (על בסיס שיפוי מלא) וכל שאר</p>
<p>העלויות וההוצאות המקצועיות) שנגרמו כתוצאה מהפרה על ידך של מי מהמצגים</p>
<p>וההתחייבויות שלך במסגרת הסכם זה.</p>
<p className='underline my-5 font-bold'>7. התמורה.</p>
<p>7.1 אלא אם, ועד שנודיע לך אחרת מראש ובכתב, השימוש שלך באפליקציה אינו כרוך</p>
<p>בתשלום.</p>
<p>7.2 המעסיק הישיר שלך ישלם לך עבור השירותים המקצועיים שניתנו על ידך (כפי</p>
<p>שתועדו באפליקציה) - בהתאם להסכם העסקתך עם המעסיק הישיר. עם השלמת</p>
<p>כל משמרת של שירותים מקצועיים, תוכל לקבל, דרך האפליקציה, חישוב של</p>
<p>התשלומים המגיעים לך מהמעסיק הישיר בעניין זה.</p>
<p>7.3 בשום מקרה החברה לא תהיה אחראית כלפיך בגין כל תשלום עבור השירותים</p>
<p>המקצועיים.</p>
<p className='underline my-5 font-bold'>8. סיום ההסכם.</p>
<p>8.1 אתה רשאי לסיים את חשבון Start2שלך, מכל סיבה שהיא, בכל עת.</p>
<p>8.2 החברה רשאית להשעות או לסגור את חשבון ה- Start2שלך, ללא הודעה</p>
<p>מוקדמת ועל פי שיקול דעתה הבלעדי: (א) אם הורו לה לעשות כן על ידי רשות</p>
<p>מוסמכת; (ב) בהתאם לייעוץ משפטי שניתן לה; (ג) בהתבסס על תלונות ו/או</p>
<p>דיווחים של לקוחות; (ד) אם לא קיבלת באופן עקבי הזמנות מיקום שהוצעו לך; או</p>
<p>(ה) אם היא סבורה שהפרת הסכם זה, את הדין החל או את מדיניות הפרטיות.</p>
<p>8.3 מבלי לגרוע מהאמור לעיל, החברה רשאית להשעות או להפסיק את שירותי</p>
<p>ההשמה שלה, מכל סיבה שהיא ולפי שיקול דעתה הבלעדי, על ידי מתן הודעה</p>
<p>לכל המשתמשים.</p>
<p>8.4 לאחר סגירת חשבונך, הסכם זה יסתיים גם הוא, למעט לכך שההוראות הבאות</p>
<p>יישארו בתוקף: 5.7-5.5, 6.5, 8.3 ו-16-10.</p>
<p className='underline my-5 font-bold'>9. הנחיות לגבי פעילות מורשית של המשתמש.</p>
<p>9.1 כמשתמש רשום ב- Start2, ובכפוף לתנאי הסכם זה, תורשה לגשת לשירותי</p>
<p>ההשמה ולהשתמש בהם. כל שימוש בשירותי ההשמה ישמש רק למטרת</p>
<p>התקשרות ומתן שירותים מקצועיים, ואתה מסכים לא להשתמש בשירותי ההשמה</p>
<p>ו/או כל תוכן הכלול בתוכם, לכל מטרה אחרת. בנוסף, אתה מתחייב:</p>
<p>9.1.1 לא להעתיק, לשנות, לשדר, לייצר עבודה נגזרת, לשכפל או לעשות</p>
<p>שימוש בכל חומר המוגן בזכויות יוצרים, לרבות (אך לא רק), תמונות,</p>
<p>סימנים מסחריים, שמות מסחריים, סימני מסחר או קניין רוחני אחר, תוכן</p>
<p>או מידע קנייני הנגיש דרך שירותי ההשמה, ללא הסכמה מראש ובכתב</p>
<p>של החברה.</p>
<p>9.1.2 לא להשתמש בשירותי ההשמה בכל דרך העלולה להפריע, לשבש או</p>
<p>להשפיע לרעה על שירותי ההשמה או השרתים או הרשתות המחוברות</p>
<p>לשירותי ההשמה.</p>
<p>9.1.3 לא להעלות וירוסים או קוד זדוני אחר או לגרום לכל נזק לחברה ו/או</p>
<p>ליכולתה של החברה לספק את שירותי ההשמה או לאבטחת שירותי</p>
<p>ההשמה.</p>
<p>9.1.4 לא לשנות, להתאים, לתת רישיון משנה, לתרגם, למכור, לבצע הנדסה</p>
<p>לאחור, לפענח ו\או לפרק כל חלק מהשירותים המקצועיים, או לגרום</p>
<p>לאחרים לעשות זאת.</p>
<p>9.1.5 לא להיכנס ו/או להשתמש בשירותי ההשמה ו/או בשירותים המקצועיים</p>
<p>ללא הרשאה או לפרסם כל אחד מהשירותים המקצועיים ללא הסכמה</p>
<p>בכתב של החברה.</p>
<p>9.1.6 לא לחדור ו/או לסרוק ו/או לבדוק את פגיעות וחולשות האפליקציה או כל</p>
<p>מערכת או רשת אחרת.</p>
<p>9.1.7 לא לעודד או לקדם כל פעילות המפרה הסכם זה.</p>
<p>9.1.8 לא להשתמש בשירותי ההשמה באופן שעלול להשפיע לרעה על</p>
<p>המוניטין של החברה.</p>
<p>9.2 החברה רשאית לחקור ולנקוט כל פעולה משפטית העומדת לרשותה בתגובה</p>
<p>לשימוש בלתי חוקי ו/או לא מורשה בשירותי ההשמה, או כל הפרה של הסכם זה,</p>
<p>לרבות (אך לא רק), סיום חשבון ה- Start2שלך.</p>
<p>9.3 מבלי לגרוע מכלליות האמור לעיל, ביחס ללקוחות ולמשתמשים, בעת השימוש</p>
<p>בשירותי ההשמה אתה מסכים:</p>
<p>9.3.1 לא להשתמש בשירותי ההשמה בכל דרך שהיא בלתי חוקית או סותרת</p>
<p>את הוראות הסכם זה.</p>
<p>9.3.2 לא להשתמש בשירותי ההשמה לכל מטרה מזיקה.</p>
<p>9.3.3 לא להתחזות לכל אדם או ישות.</p>
<p>9.3.4 לא לבקש, להשיג, להעביר ולהשתמש בסיסמאות או מידע מזהה אישי</p>
<p>השייך למשתמשים אחרים לכל מטרה, או להפיץ מידע אישי של אחר</p>
<p>ללא רשות.</p>
<p>9.3.5 לא להשתמש בחשבון של משתמש אחר, לחלוק חשבון עם משתמש</p>
<p>אחר, או לתחזק יותר מחשבון אחד.</p>
<p>9.3.6 לא ליצור חשבון נוסף אם החברה כבר סגרה את חשבונך, אלא אם</p>
<p>קיבלת אישור מראש ובכתב של החברה.</p>
<p className='underline my-5 font-bold'>10. זכויות החברה על פי ההסכם.</p>
<p>על ידי יצירת חשבון Start2אתה מעניק בזאת לחברה רישיון בלתי חוזר להשתמש בכל</p>
<p>מידע שנמסר לה על ידך במהלך השימוש בשירותי ההשמה, בכפוף לתנאי מדיניות</p>
<p>הפרטיות של החברה (כפי שתעודכן מעת לעת).</p>
<p className='underline my-5 font-bold'>11. פטור.</p>
<p>החברה מספקת את שירותי ההשמה &quot;כפי שהם&quot; ו&quot;על בסיס זמינות&quot; ואלא אם כן</p>
<p>נקבע אחרת בחוק, החברה אינה מעניקה התחייבות, אחריות ו\או מצגים מכל סוג</p>
<p>שהוא, בין אם מפורש או במשתמע, סטטוטוריים או אחרים, בהתייחס לשירותי</p>
<p>ההשמה (כולל כל התוכן הכלול בהם). החברה אינה מבטיחה או מתחייבת כי (א)</p>
<p>שירותי ההשמה יהיו ללא הפרעות, מאובטחים או נטולי שגיאות, (ב) פגמים או</p>
<p>שגיאות כלשהם בשירות יתוקנו, או (ג) לגבי כל התאמה ו/או נכונות המידע שתקבל</p>
<p>באמצעות שירותי ההשמה.</p>
<p>החברה אינה לוקחת אחריות על תוצאות השימוש בשירותי ההשמה, ואינה מעניקה</p>
<p>התחייבויות ו/או מצגים באשר לתוצאות השימוש בשירותי ההשמה (לרבות, ומבלי</p>
<p>לגרוע מכלליות האמור, השירותים המקצועיים). כל חומר שהורד או הושג בדרך</p>
<p>אחרת באמצעות השימוש בשירותי ההשמה נעשה על אחריותך ועל שיקול דעתך.</p>
<p className='underline my-5 font-bold'>12. הגבלת אחריות.</p>
<p>אלא אם כן נקבע אחרת בחוק, בשום מקרה החברה, שותפיה, עובדיה, בעלי רישיון</p>
<p>ממנה או ספקי השירותים יהיו אחראים לכל נזק עקיף, תוצאתי, עונשי, אגבי, פיצויים</p>
<p>לדוגמא ו/או נזק ספציפי (לרבות מכלי לגרוע מכלליות האמור, אובדן רווחים), בין אם</p>
<p>נגרמו במישרין או בעקיפין, או כל אובדן נתונים, שימוש, מוניטין או הפסדים בלתי</p>
<p>מוחשיים אחרים, הנובע מ: (א) הגישה שלך או השימוש או חוסר היכולת שלך לגשת</p>
<p>או להשתמש באפליקציה ו/או להשתמש בה (ב) התנהלותם של משתמשים אחרים</p>
<p>או לקוחות כלשהם ו/או צדדים שלישיים כלשהם במסגרת שירותי ההשמה,</p>
<p>באמצעותם או בעקבות השימוש בהם, או (ג) גישה או שימוש בלתי מורשים לחשבון</p>
<p>שלך.</p>
<p>אלא אם כן נקבע אחרת בחוק, בשום מקרה החבות המירבית של החברה כלפיך,</p>
<p>בגין כל התביעות הקשורות לאפליקציה ו/או לשירותי ההשמה, לא תעלה על סכום</p>
<p>של 100 ש&quot;ח.</p>
<p className='underline my-5 font-bold'>13. כוח עליון.</p>
<p>בשום מקרה לא תהיה החברה אחראית או אחראית לכל כשל או עיכוב בביצוע שירותי</p>
<p>ההשמה ו/או של השירותים המקצועיים ו/או בהתחייבויותיה על פי הסכם זה הנובעים או</p>
<p>הנגרמים, בין אם במישרין ובין אם בעקיפין, על ידי כוחות מחוץ לשליטתה. לרבות אך לא</p>
<p>רק, שביתות, הפסקות עבודה, תאונות, מגיפות, מלחמה או פעולות טרור, הפרות סדר</p>
<p>אזרחיות או צבאיות, אסונות גרעיניים או אסונות טבע או מעשי אלוהים, והפרעות, אובדן</p>
<p>או תקלות של אמצעים, תקשורת או שירותים טכנולוגיים (שירותי תוכנה או חומרה);</p>
<p>מובהר כי תודיע החברה ללקוח על אירועים כאמור ותנקוט במאמצים סבירים כדי להשיב</p>
<p>את שירותי ההשמה למתכונתם המקורית בהקדם האפשרי בנסיבות העניין.</p>
<p className='underline my-5 font-bold'>14. דין וסמכות שיפוט.</p>
<p>דיני מדינת ישראל, מבלי להתייחס לעקרונות של משפט בינלאומי פרטי, יחולו על כל</p>
<p>מחלוקת הנובעת מהסכם זה או הנוגעת אליו.</p>
<p>אתה מסכים במפורש שסמכות השיפוט הבלעדית לכל סכסוך הנובע או הנוגע להסכם</p>
<p>זה מצויה בבתי המשפט בתל אביב, ישראל, וכן אתה מסכים באופן מפורש להפעלת</p>
<p>סמכות השיפוט בבתי המשפט של מדינת ישראל בקשר עם כל מחלוקת כזו, לרבות כל</p>
<p>תביעה הקשורה לחברה או לחברות הבנות שלה, לעובדי החברה, קבלני החברה, נושאי</p>
<p>משרה ודירקטורים בחברה.</p>
<p className='underline my-5 font-bold'>15. תוקף ההסכם.</p>
<p>אם הוראה כלשהי בהסכם זה תימצא כלא תקפה על ידי בית משפט מוסמך, חוסר</p>
<p>התוקף של הוראה כזו לא ישפיע על תוקף שאר ההוראות של הסכם זה, אשר יישארו</p>
<p>בתוקף. שום ויתור על אחד מתנאי הסכם זה לא ייחשב כוויתור נוסף או מתמשך על תנאי</p>
<p>כזה או כל תנאי אחר.</p>
<p className='underline my-5 font-bold'>16. שיפוי.</p>
<p>בהיקף המירבי המותר על פי דין, אתה מסכים לשפות, להגן ולפטור מנזק את החברה,</p>
<p>החברות הקשורות אליה, את נושאי המשרה, הדירקטורים, הסוכנים והעובדים של</p>
<p>החברה והחברות הקשורות אליה מכל תלונה, דרישה, תביעה, נזק, הפסד, עלויות,</p>
<p>התחייבויות והוצאות, לרבות שכר טרחת עורך דין סביר, הנובעים או קשורים בכל דרך</p>
<p>לגישה או לשימוש שלך באפליקציה ו/או שירותי ההשמה, חשבון ה- Start2שלך, או</p>
<p>הפרת הסכם זה על ידך.</p>
<p className='underline my-5 font-bold'>17. ההסכם המלא.</p>
<p>הסכם זה ומדיניות הפרטיות מכילים את כל ההסכמות בינך לבין החברה לגבי השימוש</p>
<p>בשירותי ההשמה.</p>
        
        <div >

            </div>
            
            </div>
        </div>
    )
}
