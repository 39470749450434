import React from 'react'

export function PrivacyPolicyEng() {
    

    return (
        <div className='mb-36 mx-auto text-left' dir='ltr' style={{maxWidth:'700px',paddingLeft:'16px',paddingRight:'16px'}}>
            <div className='mt-10'>
        <h1 className='text-2xl sm:text:3xl text-center mb-6'>Privacy Policy</h1>
        <div >
        <p>M.H,R Wearetech H.R Ltd. (the &ldquo;Company&rdquo;) provides, as part of its services, a</p>
<p>platform (the &ldquo;App&rdquo;) which enables restaurants, clubs, cafes and/or any other</p>
<p>institutions serving food and/or beverages who use the Customers&rsquo; version of the App</p>
<p>(collectively: &ldquo;Customers&rdquo;) to engage and contract Service Providers for the purposes</p>
<p>of providing temporary or ongoing dishwashing services (and/or any other general</p>
<p>kitchen works) (the &ldquo;Professional Services&rdquo;) and which enables Service Providers to be</p>
<p>offered and to accept proposals for the provision of Professional Services. For purposes</p>
<p>of this Privacy Policy the term &ldquo;Service Providers&rdquo; shall include persons registered as</p>
<p>Service Providers, potential service providers, or anyone who visits the Company&rsquo;s</p>
<p>website or downloads the Service Providers&rsquo; version of the App. All types of service</p>
<p>provided by the Company to the Customers and/or to the Service Providers, shall</p>
<p>hereunder be referred to as the &ldquo;Platform Services&rdquo;. The Customers and Service</p>
<p>Providers shall be collectively referred to as the &quot;Users&rdquo;.</p>
<p>In order to offer, develop, provide, advertise and maintain the Platform Services, the</p>
<p>Company collects and processes information, including but not limited to personal</p>
<p>information that identifies, relates to, describes, is capable of being associated with, or</p>
<p>could reasonably be linked, directly or indirectly, with a particular Service Provider,</p>
<p>which shall hereby be referred to as &ldquo;Information&rdquo;.</p>
<p>In particular, the Company collects the following categories of Information from the</p>
<p>Service Providers:</p>
        
       

</div>
           
            
            <div>
            <table className='mt-10 mb-10' style={{tableLayout:'fixed',width:'100%',border:'1px solid grey',borderCollapse:'collapse'}}>
  <thead>
    <tr>
      <th style={{border:'1px solid grey',borderCollapse:'collapse',textAlign:'center'}}>Category</th>
      <th style={{border:'1px solid grey',borderCollapse:'collapse',textAlign:'center'}}>Examples</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Identifiers.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>A real name, alias, postal address, unique personal identifier,
government identifiers, online identifier, Internet Protocol
address, email address, account name, or other similar
identifiers.</td>
    </tr>
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>
      Location.
      </td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>GPS location of mobile device, Physical location or movements

(while the App is in use).</td>
    </tr>
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Billing and financial
details.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>where applicable: bank account and related payment details,
invoices and transaction history, tax forms, etc.</td>
    </tr>
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Orders, transactions,
and services details.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Professional Services assumeג and performed by Service
Providers, time spent providing the services, , contact details,
shift preferences, and additional details provided by the Service
Providers in the course of their use of the Platform Services.</td>
    </tr>
   
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Usage information.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Connectivity, technical and aggregated usage data and activity
logs, log-in and log-out time, user agent, IP addresses, device
and mobile app data (like type, OS, device id, app version,
browser version, locale, time-zone and language settings used),
session recordings and analytics, and the cookies and pixels
installed or utilized on their device);</td>
    </tr>
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Personal information.</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Physical characteristics or description, picture, work/stay visa,

identification certificate.</td>
    </tr>
    <tr>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>Rating and Grades</td>
      <td  style={{border:'1px solid grey',borderCollapse:'collapse',padding:'10px'}}>The rating provided by a Customer who used the Service

Provider’s Professional Services.</td>
    </tr>
   
    
    
  </tbody>
</table>
<p>
This Privacy Policy applies to all methods through which the Platform Services are
operated including but not limited to the Company’s website and app, and to each of the
Platform Services provided by the Company.
</p>
<p className='font-bold my-5'>1. COLLECTION OF INFORMATION.</p>
<p>The main sources from which the Company collects Information are:</p>
<p>1.1 Users&rsquo; activities when using the Platform Services.</p>
<p>1.2 Information provided voluntarily with consent by Service Provides to the</p>
<p>Company.</p>
<p>1.3 Information provided by third parties, such as sub-contractors, which the</p>
<p>Company partners with, in order to provide the Platform Services.</p>
<p>2. COMPANY USE OF INFORMATION.</p>
<p>The Company uses and processes the Information for the following purposes:</p>
<p>2.1 In order to develop, improve and deliver the Platform Services.</p>
<p>2.2 In order to connect between Customers and Service Providers.</p>
<p>2.3 For security purposes.</p>
<p>2.4 To ensure legal compliance.</p>
<p>2.5 In case of legal proceedings pertaining to Users.</p>
<p>2.6 To verify the identity of Service Providers.</p>
<p className='font-bold my-5'>3. TRANSFER OF INFORMATION.</p>
<p>The Company will maintain the Information under strict confidence, in accordance with</p>
<p>the provisions of applicable data protection laws and regulations. The Company may</p>
<p>transfer or share the Information, or portions of the Information in the following</p>
<p>circumstances:</p>
<p>3.1 With the data subject&rsquo;s consent.</p>
<p>3.2 To the extent required for the operation of the Platform Services. For</p>
<p>example, sharing limited Information (such as name, picture, identification</p>
<p>certificates, visas and proximity to the Customer) with Customers, in order</p>
<p>to create a match between same and Service Providers.</p>
<p>3.3 To comply with legal requirements.</p>
<p>3.4 To assist in the prevention or investigation of a crime.</p>
<p>3.5 To protect the safety of another person.</p>
<p>3.6 To enforce the Company&rsquo;s legal rights.</p>
<p>3.7 In the case of a merger, sale, acquisition, reorganization, dissolution or other</p>
<p>forms of change of control of the Company. However, in such cases, where</p>
<p>the successor/surviving entity intends to use the Information in a manner</p>
<p>that is materially inconsistent with the above provisions, the Company will</p>
<p>provide the Service Providers a prior written notice, so to allow them to opt-</p>
<p>out of relevant Professional Services.</p>
<p className='font-bold my-5'>4. SERVICE PROVIDERS&rsquo; RIGHTS.</p>
<p>4.1 Subject and according to any applicable data protection laws and</p>
<p>regulations, a Service Provider may file a request to review, update and/or</p>
<p>delete the Information related to him/her and collected by the Company.</p>
<p>Such request shall be sent by the Service Provider (proof of identity may be</p>
<p>required), to the following Email address: <a data-fr-linked="true" href="mailto:service@wearetech.me">service@wearetech.me</a>.</p>
<p>4.2 The Company shall not be required to reidentify or otherwise link</p>
<p>information that is not maintained in a manner that would be considered</p>
<p>personal information.</p>
<p>4.3 In case that a Service Provider believes that the Information related to</p>
<p>him/her is inaccurate, partial or not updated, he/she may send a request for</p>
<p>updating the Information.</p>
<p>4.4 In the case where a request to receive information is also related to</p>
<p>Customers, such as a message or call received from same through the</p>
<p>Platform Services, then the respective Customer must be a party to such</p>
<p>request in order for the Company to accept it.</p>
<p>4.5 The Company will make its best efforts to respond, review, update and/or</p>
<p>delete said requests as early as possible, and in any case no longer than 90</p>
<p>days from the receipt of such request. Where a longer period is required,</p>
<p>due to the nature and scope of such request, the Company may extend such</p>
<p>period by additional 90 days, by providing a written notice to the respective</p>
<p>Service Provider.</p>
<p>4.6 Where a Service Provider has requested his/her Information, the Company</p>
<p>shall provide the Information in a portable and readily usable format that</p>
<p>allows the transfer of such Information to a third party be such Service</p>
<p>Provider.</p>
<p>4.7 Such Information may include:</p>
<p>4.7.1 The categories of Information the Company collected about the</p>
<p>Service Provider.</p>
<p>4.7.2 The categories of sources for the Information the Company</p>
<p>collected about the Service Provider.</p>
<p>4.7.3 The specific pieces of Information the Company collected about</p>
<p>the Service Provider.</p>
<p>4.8 Without derogating from the Company&rsquo;s rights under any applicable laws</p>
<p>and/or regulations, the Company may reject deletion requests, where the</p>
<p>retention of such Information is required in order to: Perform the</p>
<p>Company&rsquo;s undertakings towards the Customers and/or Service Providers;</p>
<p>detect security incidents; identify and debug system errors; enforce the</p>
<p>Company&rsquo;s legal and/or contractual rights; protect against illegal activity or</p>
<p>prosecute those responsible for such activity, or where the disclosure,</p>
<p>update and/or deletion of such Information is prohibited under any</p>
<p>applicable law.</p>
<p>4.9 No Service Provider will be discriminated against in connection with the</p>
<p>submission of such requests.</p>
<p className='font-bold my-5'>5. &nbsp;STORAGE PERIOD.</p>
<p>Company does not store Service Provider&rsquo;s personal data longer than is legally</p>
<p>permitted and necessary for the purposes of providing the Platform Services or</p>
<p>the relevant parts thereof. The storage period depends on the nature of the</p>
<p>information and on the purposes of processing. The maximum period may</p>
<p>therefore vary per use.</p>
<p>As the provision of the Platform Services and the performance of the</p>
<p>Professional Services is subject to certain legal and contractual obligations, most</p>
<p>personal data relating to a Users&rsquo; use of their respective accounts the Company</p>
<p>Services will be deleted once such use may no longer be subject to any legal</p>
<p>proceedings or be reasonably necessary for our legal obligations or legitimate</p>
<p>interests such as claims handling, bookkeeping, internal reporting and</p>
<p>reconciliation purposes. All personal data relating to a Service Provider&rsquo;s</p>
<p>account will be deleted within a period of 10 years after the Service Provider has</p>
<p>deleted its user account, with the exception of personal data required in certain</p>
<p>rare situations such as legal proceedings.</p>
<p className='font-bold my-5'>6. COOKIES POLICY.</p>
<p>6.1 The Company uses cookies and similar technologies in order to recognize</p>
<p>Users and their devices, to collect statistical data, for security purposes and</p>
<p>in order to provide secure and improved Platform Services, including but</p>
<p>not limited to, remembering User&rsquo;s preferences, content personalization and</p>
<p>targeted advertising.</p>
<p>6.2 Using the Company&rsquo;s Platform Services, including, but not limited to</p>
<p>visiting the Company&rsquo;s website and app, will be regarded as User&rsquo;s consent</p>
<p>for collection of information for the purposes mentioned in the Company&rsquo;s</p>
<p>Cookies Policy (this Section 6).</p>
<p>6.3 Some web browsers enable their users to block cookies. Instructions as to</p>
<p>how to block cookies can be found in the help section of your browser.</p>
<p>Using such options may adversely affect the Company&rsquo;s ability to provide</p>
<p>the Platform Services.</p>
<p className='font-bold my-5'>7. PROTECTION OF INFORMATION.</p>
<p>The Company keeps the Information in strict confidence, and in compliance</p>
<p>with the requirements of applicable data protection laws and regulations as</p>
<p>relevant to each User.</p>
<p className='font-bold my-5'>8. CHILDREN&rsquo;S PRIVACY.</p>
<p>The Services are restricted to Users who are 18 years of age or older. The</p>
<p>Company does not permit Users under 18 years of age to use the Platform</p>
<p>Services in any for or manner and does not knowingly collect Information from</p>
<p>anyone under the age of 18.</p>
<p className='font-bold my-5'>9. RESIDENTS OF ANY JURISDICTION OTHER THAN ISRAEL.</p>
<p>Currently, the Company does not offer and/or provide any of its Platform</p>
<p>Services out of Israel. If you are not a resident of Israel, you are hereby</p>
<p>requested not to use the Services in any form or manner and are not protected by</p>
<p>the terms of this Privacy Policy.</p>
<p className='font-bold my-5'>10. AMENDMENTS TO THE COMPANY&rsquo;S PRIVACY POLICY.</p>
<p>The Company may change this Privacy Policy from time to time. The updated</p>
<p>version of the Privacy Policy shall be available to Users on the Company&rsquo;s</p>
<p>website and on the App. Any material change in the Company&rsquo;s Privacy Policy</p>
<p>may be announced, either in the Company&rsquo;s website or in any other manner, as</p>
<p>the Company shall see fit.</p>
            </div>
            
            </div>
        </div>
    )
}
