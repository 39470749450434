import React from 'react'
import { Logo } from './Logo'
import {useTranslation} from 'react-i18next'
import { PrivacyPolicyHeb } from './TermsOfUse/PrivacyPolicyHeb';
import { PrivacyPolicyEng } from './TermsOfUse/PrivacyPolicyEng';
import { TermsOfUseCustomersHeb } from './TermsOfUse/TermsOfUseCustomersHeb';
import { TermsOfUseCustomersEng } from './TermsOfUse/TermsOfUseCustomersEng';
import { TermsOfUseServicesHeb } from './TermsOfUse/TermsOfUseServicesHeb';
import { TermsOfUseServicesEng } from './TermsOfUse/TermsOfUseServicesEng';
import { TermsAndConditionsCustomersEng } from './TermsOfUse/TermsAndConditionsCustomersEng';
import { TermsAndConditionsServProvidersEng } from './TermsOfUse/TermsAndConditionsServProvidersEng';

export function TermsAndConditions(props) {
    
    const [t,i18n] =useTranslation("global");

    return (
        <div className='max-w-[1365px] m-auto'>
            <div className='flex justify-center'>
        <Logo/>
        </div>
        {/* <div className='' >
            <div className='cursor-pointer text-center mt-20'>
                <a>
                    <span className='text-2xl'>{t("Terms.TermsServices")}</span>
                </a>
            </div>
            <div className='cursor-pointer text-center mt-10'>
                <a>
                    <span className='text-2xl'>{t("Terms.TermsCustomers")} </span>
                </a>
            </div>
            <div className='cursor-pointer text-center mt-10'>
                <a>
                    <span className='text-2xl'>{t("Terms.Terms")} </span>
                </a>
            </div>
        </div> */}
        <div>
            {window.location.pathname === '/PrivacyPolicyHeb' && <PrivacyPolicyHeb/>  }
            {window.location.pathname === '/PrivacyPolicyEng' && <PrivacyPolicyEng/> }
            {window.location.pathname === '/TermsOfUseCustomersHeb' && <TermsOfUseCustomersHeb/> }
            {window.location.pathname === '/TermsOfUseCustomersEng' && <TermsOfUseCustomersEng/> }
            {window.location.pathname === '/TermsOfUseServicesHeb' && <TermsOfUseServicesHeb/> }
            {window.location.pathname === '/TermsOfUseServicesEng' && <TermsOfUseServicesEng/> }
            {/* <TermsAndConditionsCustomersEng/> */}
            {/* <TermsAndConditionsServProvidersEng/> */}
            </div> 
        
        </div>
    )
}
