import React from "react";

export function TermsOfUseServicesEng() {
  return (
    <div
      className="mb-36 mx-auto text-left"
      dir="ltr"
      style={{ maxWidth: "700px", paddingLeft: "16px", paddingRight: "16px" }}
    >
      <div className="mt-10">
        <h1 className="text-2xl sm:text:3xl text-center mb-6 underline">
          Terms of Use
        </h1>
        <p className="underline my-5 font-bold">
          Please carefully read these terms and conditions (collectively:
          "Agreement") before creating a user account and/or using the placement
          services.
        </p>
        <p className="underline my-5 font-bold">Introduction.</p>
        <p>
          This Agreement governs all use of (1) the [Start2] website
          [www.start2.co.il] (collectively: "Website"), owned by We Wash
          Technologies Ltd., a company located at 34 Hagra St., Tel Aviv,
          Israel, legally incorporated under the laws of the State of Israel
          (collectively: "Company" or "Start2"); (2) the Company's mobile
          applications to which this Agreement applies (collectively: "App");
          and/or (3) any services offered by the Company accessible through the
          Website and/or App.
        </p>
        <p className="underline my-5 font-bold">1. Definitions.</p>
        <p>"App" as defined in the preamble of this Agreement.</p>
        <p>
          "Applicable Law" means all laws and regulations in force in Israel.
        </p>
        <p>
          "Placement Order" means a request for professional services submitted
          by a customer through the App.
        </p>
        <p>
          "User Account" means the user account in the Company containing the
          user's personal information, including full name, address, date of
          birth, nationality, government residency/work permits, photo,
          acceptance/rejection of offers for professional services, timesheets
          of services performed, feedback from customers, etc. (collectively:
          "Personal Information").
        </p>
        <p>
          "Customers" means restaurants, clubs, cafes, and/or any other
          establishments serving food and/or beverages, using the App and
          seeking professional services from users.
        </p>
        <p>
          "Privacy Policy" means the Company's privacy policy available on the
          Website and/or App. The Privacy Policy may be amended and/or updated
          by the Company from time to time.
        </p>
        <p>
          "User" or "You" means any individual using the App to receive
          placement orders for professional services and choosing, at their
          discretion, to accept any, all, or none of these placement orders and
          perform such services as stipulated in the accepted offers through the
          App.
        </p>
        <p>
          "Placement Services" means the platform, via the App or otherwise,
          connecting customers and users for the purpose of professional
          services based on placement orders.
        </p>
        <p>
          "Professional Services" means dishwashing services (and/or any other
          kitchen-related services) provided by a user to customers based on
          placement orders.
        </p>
        <p className="underline my-5 font-bold">
          2. Acceptance of This Agreement and Privacy Policy.
        </p>
        <p>
          By using and/or registering for the App, including (but not limited
          to) creating a Start2 User Account (as defined above), whether via
          mobile device, mobile app, or computer, you voluntarily agree to be
          bound by: (1) this Agreement; and (2) the Company's Privacy Policy.
        </p>
        <p>
          If you do not accept, agree, or wish in any way to be bound by this
          Agreement and/or the Privacy Policy, do not create a User Account
          and/or use the App and/or Placement Services.
        </p>
        <p>
          The Company reserves the right to modify and update this Agreement
          from time to time. The latest updated version of this Agreement will
          be available on the Website/App. Your continued use of the Website,
          App, or Placement Services constitutes your agreement to the most
          recent version of this Agreement.
        </p>
        <p className="underline my-5 font-bold">3. Eligibility.</p>
        <p>
          3.1 To use the Placement Services and maintain a User Account, you
          must be over 18 years of age.
        </p>
        <p>
          3.2 By using the App and Placement Services, you represent and warrant
          that:
        </p>
        <p>
          3.2.1 You have all necessary permits to perform professional services.
        </p>
        <p>
          3.2.2 You are not affected by any illness and/or disability that
          prevents you from performing professional services and/or working in
          the restaurant and bar industry.
        </p>
        <p>
          3.2.3 You have carefully read this Agreement and the Privacy Policy
          and understand their terms.
        </p>
        <p>
          3.2.4 You have been provided the opportunity to obtain a
          translation/explanation of this Agreement and Privacy Policy in your
          native language.
        </p>
        <p>
          3.2.5 You have never been convicted of a felony and/or a sexual or
          violent offense, and you are not required to register as a sex
          offender under applicable law.
        </p>
        <p>
          3.2.6 You have entered into an employment agreement with
          _______________ (collectively: "Direct Employer"), and such agreement
          is valid and in force.
        </p>
        <p>
          3.2.7 You will comply at all times with the provisions of this
          Agreement, the Privacy Policy, and Applicable Law.
        </p>
        <p>
          3.2.8 You will promptly notify the Company if any of the conditions
          listed in Sections 3.2.1 - 3.2.7 no longer apply.
        </p>
        <p className="underline my-5 font-bold">
          4. Use of Your Start2 Account.
        </p>
        <p>
          4.1 You are solely responsible for maintaining the confidentiality of
          the login details you use to register for your Start2 Account and for
          all activities occurring under this account. It is therefore
          recommended to keep your Start2 account credentials strictly
          confidential.
        </p>
        <p>
          4.2 Your Start2 Account is personal and non-transferable. You may not
          share placement orders with anyone else, nor may you allow anyone else
          to use your Start2 Account.
        </p>
        <p className="underline my-5 font-bold">5. Placement Services.</p>
        <p>
          5.1 The app provides a means to enable customers seeking professional
          services (whether on an urgent, one-time, or other basis) to connect
          with users and vice versa based on the geographic location of the
          customers and users and the availability of the users. The company
          does not provide dishwashing and/or cleaning services and is not an
          agent or partner of the customers, nor an agent, partner, and/or
          employer of the user. The company is a technology service provider
          using an electronic platform to provide placement services.
        </p>
        <p>
          5.2 Users must download and install the app on their mobile device,
          and they are responsible for all costs associated with this device,
          including (but not limited to) data usage or call fees.
        </p>
        <p>
          5.3 Users are free to use the app, accept, ignore, and/or reject
          placement orders at any time and are not required to accept a minimum
          number of placement orders, nor are they restricted to a maximum
          number of placement orders, subject to the provisions of Section 8.2
          (d) below, except that users must ensure compliance with all
          regulatory requirements imposed on them as providers of professional
          services, especially regarding rest periods.
        </p>
        <p>
          5.4 Notwithstanding the provisions of Section 5.1, the company does
          not guarantee that any user will receive a minimum number of placement
          orders (and/or performance orders at all), nor does it guarantee
          uninterrupted or error-free availability and/or usage of the app. The
          company, at its sole discretion, may prioritize placement orders
          and/or users as it sees fit. The company will not be liable for any
          damage, loss, claims, costs, or expenses arising from or resulting
          from planned or unplanned downtime, unavailability, or slowness.
        </p>
        <p>
          5.5 Nothing in these terms shall be construed to create any
          employer-employee relationship between the company and the user.
          Likewise, the company will not be liable to the user, and the user
          hereby irrevocably waives all claims against the company and/or its
          directors, officers, and/or employees regarding the provision of
          professional services by the user, acts or omissions of customers,
          and/or any acts or omissions of the direct employer.
        </p>
        <p>
          5.6 Nothing in this agreement shall be construed to create an agency
          relationship between the user and the company. The user will have no
          right or authority to enter into agreements on behalf of the company
          or bind the company in any way with third parties unless explicitly
          authorized in writing.
        </p>
        <p>
          5.7 You are solely responsible for your interactions with customers.
          You understand that the company does not conduct any background checks
          on customers, nor does it make representations or warranties regarding
          the customers and/or their work environment.
        </p>
        <p className="underline my-5 font-bold">6. Professional Services.</p>
        <p>
          6.1 The user acknowledges that their mobile device must provide their
          geographic location to enable them to receive placement orders based
          on their location. The user acknowledges and agrees that their
          geographic location information will be accessible by the app and
          displayed to the company and customers when they are logged in.
        </p>
        <p>
          6.2 The user acknowledges and agrees that it is their sole
          responsibility to report arrival at the customer’s premises and
          completion of the professional services through the app, and failure
          to do so may result in non-payment for these professional services.
        </p>
        <p>
          6.3 In the event of illness between the time of placement order
          approval and the time of execution, you must immediately notify the
          company and the customer through the app.
        </p>
        <p>
          6.4 The user agrees to perform the professional services diligently
          and professionally, arrive at the customer’s premises at the time
          specified in the placement order, cooperate with the customer’s team,
          and follow the customer’s instructions.
        </p>
        <p>
          6.5 You will indemnify the company and customers against any
          liabilities, costs, expenses, damages, and losses (including but not
          limited to direct, indirect, or consequential damages, loss of profit,
          loss of reputation, and all interest, fines, legal costs on a full
          indemnity basis, and all other professional costs and expenses)
          arising from your breach of any representations and obligations under
          this agreement.
        </p>
        <p className="underline my-5 font-bold">7. Compensation.</p>
        <p>
          7.1 Unless and until otherwise notified in advance and in writing,
          your use of the app does not require payment.
        </p>
        <p>
          7.2 Your direct employer will pay you for the professional services
          provided by you (as documented in the app) per your employment
          agreement with the direct employer. Upon completing each shift of
          professional services, you will receive, via the app, a calculation of
          the payments due to you from the direct employer regarding this
          matter.
        </p>
        <p>
          7.3 In no event shall the company be liable to you for any payment for
          the professional services.
        </p>
        <p className="underline my-5 font-bold">8. Termination of Agreement.</p>
        <p>
          8.1 You may terminate your Start2 account for any reason at any time.
        </p>
        <p>
          8.2 The company may suspend or close your Start2 account without prior
          notice and at its sole discretion: (a) if directed to do so by a
          competent authority; (b) based on legal advice received; (c) based on
          complaints and/or reports from customers; (d) if you have consistently
          not accepted placement orders offered to you; or (e) if it believes
          you have violated this agreement, applicable law, or the privacy
          policy.
        </p>
        <p>
          8.3 Without prejudice to the foregoing, the company may suspend or
          terminate its placement services, for any reason and at its sole
          discretion, by providing notice to all users.
        </p>
        <p>
          8.4 Upon closing your account, this agreement will also terminate,
          except that the following provisions will remain in force: 5.5-5.7,
          6.5, 8.3, and 10-16.
        </p>
        <p className="underline my-5 font-bold">
          9. Guidelines for Authorized User Activities.
        </p>
        <p>
          9.1 As a registered Start2 user, and subject to the terms of this
          agreement, you are authorized to access and use the placement
          services. Any use of the placement services is solely for the purpose
          of engaging and providing professional services, and you agree not to
          use the placement services or any content contained therein for any
          other purpose. Additionally, you commit:
        </p>
        <p>
          9.1.1 Not to copy, modify, transmit, create derivative works from,
          reproduce, or make use of any copyrighted material, including (but not
          limited to) images, trademarks, trade names, trade symbols, or other
          proprietary content or information accessible through the placement
          services, without prior written consent from the company.
        </p>
        <p>
          9.1.2 Not to use the placement services in any way that could disrupt,
          interfere with, or adversely affect the placement services or the
          servers or networks connected to the placement services.
        </p>
        <p>
          9.1.3 Not to upload viruses or other malicious code or cause any
          damage to the company or its ability to provide the placement services
          or ensure their security.
        </p>
        <p>
          9.1.4 Not to alter, adapt, sublicense, translate, sell, reverse
          engineer, decompile, or disassemble any part of the professional
          services or cause others to do so.
        </p>
        <p>
          9.1.5 Not to access or use the placement services or professional
          services without authorization or to publish any of the professional
          services without prior written consent from the company.
        </p>
        <p>
          9.1.6 Not to penetrate or scan for vulnerabilities or weaknesses in
          the app or any other system or network.
        </p>
        <p>
          9.1.7 Not to encourage or promote any activity that violates this
          agreement.
        </p>
        <p>
          9.1.8 Not to use the placement services in a way that could harm the
          reputation of the company.
        </p>
        <p>
          9.2 The company reserves the right to investigate and take any legal
          action available in response to illegal or unauthorized use of the
          placement services or any violation of this agreement, including (but
          not limited to) termination of your Start2 account.
        </p>
        <p>
          9.3 Without limiting the generality of the above, regarding customers
          and users, when using the placement services, you agree:
        </p>
        <p>
          9.3.1 Not to use the placement services in any way that is illegal or
          contrary to the provisions of this agreement.
        </p>
        <p>9.3.2 Not to use the placement services for any harmful purpose.</p>
        <p>9.3.3 Not to impersonate any person or entity.</p>
        <p>
          9.3.4 Not to request, obtain, transfer, or use passwords or personally
          identifiable information belonging to other users for any purpose, or
          distribute someone else’s personal information without permission.
        </p>
        <p>
          9.3.5 Not to use another user’s account, share an account with another
          user, or maintain more than one account.
        </p>
        <p>
          9.3.6 Not to create an additional account if the company has already
          closed your account unless you have received prior written permission
          from the company.
        </p>
        <p className="underline my-5 font-bold">
          10. Rights of the Company under the Agreement.
        </p>
        <p>
          By creating a Start2 account, you hereby grant the company an
          irrevocable license to use any information provided by you during the
          use of the placement services, subject to the terms of the company’s
          privacy policy (as updated from time to time).
        </p>
        <p className="underline my-5 font-bold">11. Disclaimer.</p>
        <p>
          The company provides the placement services "as is" and "as
          available," and unless otherwise required by law, the company makes no
          warranties, guarantees, or representations of any kind, whether
          express, implied, statutory, or otherwise, concerning the placement
          services (including all content contained therein). The company does
          not warrant or guarantee that (a) the placement services will be
          uninterrupted, secure, or error-free, (b) defects or errors will be
          corrected, or (c) any information obtained through the placement
          services will be accurate or reliable.
        </p>
        <p>
          The company assumes no responsibility for the results of using the
          placement services and makes no guarantees or representations about
          the results of using the placement services (including, without
          limitation, the professional services). Any material downloaded or
          otherwise obtained through the use of the placement services is done
          at your discretion and risk.
        </p>
        <p className="underline my-5 font-bold">12. Limitation of Liability.</p>
        <p>
          Unless otherwise required by law, in no event shall the company, its
          partners, employees, licensees, or service providers be liable for any
          indirect, consequential, punitive, incidental, exemplary, or specific
          damages (including, without limitation, loss of profits), whether
          direct or indirect, or any loss of data, use, reputation, or
          intangible losses arising from: (a) your access to or use of or
          inability to access or use the app and/or placement services; (b) the
          conduct of any users, customers, or third parties through or as a
          result of the placement services; or (c) unauthorized access to or use
          of your account.
        </p>
        <p>
          Unless otherwise required by law, in no event shall the company’s
          maximum liability to you for all claims related to the app and/or
          placement services exceed the amount of 100 NIS.
        </p>
        <p className="underline my-5 font-bold">13. Force Majeure.</p>
        <p>
          The company shall not be held liable for any failure or delay in
          performing the placement services and/or professional services and/or
          its obligations under this agreement due to events beyond its control,
          including but not limited to strikes, work stoppages, accidents,
          pandemics, war, acts of terrorism, civil or military disturbances,
          nuclear or natural disasters, or acts of God. The company will notify
          users of such events and make reasonable efforts to resume the
          placement services as soon as possible under the circumstances.
        </p>
        <p className="underline my-5 font-bold">
          14. Governing Law and Jurisdiction.
        </p>
        <p>
          The laws of the State of Israel, without reference to its conflict of
          laws principles, shall govern any dispute arising from or relating to
          this agreement. You expressly agree that the exclusive jurisdiction
          for any dispute arising under or related to this agreement lies with
          the courts in Tel Aviv, Israel, and you further consent to the
          exercise of jurisdiction by such courts.
        </p>
        <p className="underline my-5 font-bold">
          15. Validity of the Agreement.
        </p>
        <p>
          If any provision of this agreement is found to be invalid by a
          competent court, the invalidity of that provision shall not affect the
          validity of the remaining provisions of this agreement, which shall
          remain in full force and effect. No waiver of any term of this
          agreement shall be deemed a further or continuing waiver of such term
          or any other term.
        </p>
        <p className="underline my-5 font-bold">16. Indemnification.</p>
        <p>
          To the fullest extent permitted by law, you agree to indemnify,
          defend, and hold harmless the company, its affiliates, and their
          respective officers, directors, agents, and employees from any claim,
          demand, action, damage, loss, cost, liability, and expense, including
          reasonable attorney’s fees, arising out of or related in any way to
          your access to or use of the app and/or placement services, your
          Start2 account, or your breach of this agreement.
        </p>
        <p className="underline my-5 font-bold">17. Entire Agreement.</p>
        <p>
          This agreement and the privacy policy contain the entire agreement
          between you and the company regarding the use of the placement
          services.
        </p>
        <div></div>
      </div>
    </div>
  );
}
