import React from "react";

export function TermsOfUseCustomersEng() {
  return (
    <div
      className="mb-36 mx-auto text-left"
      dir="ltr"
      style={{ maxWidth: "700px", paddingLeft: "16px", paddingRight: "16px" }}
    >
      <div className="mt-10">
        <h1 className="text-2xl sm:text:3xl text-center mb-6">Terms of Use</h1>
        <p className=" font-bold">
          Please read carefully these terms and conditions ("Agreement") before
          creating a WeWash account and/or using the Placement Services.
        </p>
        <p className="underline my-5 font-bold">Introduction.</p>
        <p>
          This Agreement governs all uses of (1) the website [WeWash]
          [www.start2.co.il] (collectively: "Website"), owned by WeWash
          Technologies Ltd., a company located at 34 HaGra Street, Tel Aviv,
          Israel, and legally incorporated under the laws of the State of Israel
          (collectively: "Company" or "Start2"); (2) the Company's mobile
          application(s) to which this Agreement applies (collectively:
          "Application"); and/or (3) any of the services offered by the Company
          accessible through the Website and/or Application.
        </p>
        <p className="underline my-5 font-bold">1. Definitions.</p>
        <p>"Application" as defined in the introduction to this Agreement.</p>
        <p>
          "Applicable Law" means any and all laws and regulations applicable in
          Israel.
        </p>
        <p>
          "Placement Order" means a request for professional services sent by
          the Customer via the Application.
        </p>
        <p>
          "Customer Account" means the customer's account within the Company
          containing the customer's personal information, including full name,
          address, email address, phone number, payment details, credit card
          details, previous placement orders, actual service hours reports,
          service provider feedbacks, and ... (collectively: "Personal
          Information").
        </p>
        <p>
          "Customer" or "You" means the user of the Placement Services
          (restaurants, clubs, cafes, and/or any other establishment serving
          food and/or drinks that uses the Application and is interested in
          receiving professional services from service providers).
        </p>
        <p>
          "Privacy Policy" means the Company's Privacy Policy, available on the
          Website and/or Application. The Privacy Policy may be amended and/or
          updated by the Company from time to time.
        </p>
        <p>
          "Service Providers" means any person using the Application to receive
          placement orders for professional services through the Application and
          to accept, at their discretion, all or part of such placement orders
          (or none at all) - and to perform such professional services according
          to the accepted offers via the Application.
        </p>
        <p>
          "Placement Services" means the platform, via the Application or by any
          other means, connecting Customers with Service Providers for the
          purpose of performing professional services based on suitable
          placement orders.
        </p>
        <p>
          "Professional Services" means dishwashing services (and/or any other
          general services related to kitchen operations) provided by Service
          Providers to Customers in accordance with the relevant placement
          orders.
        </p>
        <p className="underline my-5 font-bold">
          2. Agreement to this Agreement and Acceptance of Privacy Policy.
        </p>
        <p>
          By using and/or registering to the Application, including (but not
          limited to) creating a Start2 user account (as defined above), whether
          through a mobile device, mobile application, or computer, you agree
          and willingly accept to be bound by: (1) this Agreement; and (2) the
          Company's Privacy Policy.
        </p>
        <p>
          If you do not accept, agree, or wish in any way to be bound by this
          Agreement and/or the Privacy Policy, do not create a user account
          and/or use the Application and/or Placement Services.
        </p>
        <p>
          The Company reserves the right to modify and update this Agreement
          from time to time. The most updated version of this Agreement is
          available on the Website/Application. Your continued use of the
          Website, Application, or Placement Services will be deemed as your
          consent to the most updated version of this Agreement.
        </p>
        <p className="underline my-5 font-bold">3. Eligibility.</p>
        <p>
          3.1 You must be over the age of 18 to use the Placement Services and
          to open and hold a Customer Account.
        </p>
        <p>
          3.2 By using the Application and the Placement Services, you hereby
          represent and warrant that:
        </p>
        <p>
          3.2.1 You have the right, authority, and capacity to enter into this
          Agreement and comply with all terms and conditions herein.
        </p>
        <p>
          3.2.2 You will comply with all laws, rules, and regulations regarding
          your use of the Placement Services and/or any other services governed
          by the Placement Services.
        </p>
        <p>
          3.2.3 You have carefully read this Agreement and the Privacy Policy
          and understood their provisions.
        </p>
        <p>
          3.2.4 You will comply, at all times, with the provisions of this
          Agreement, the Privacy Policy, and Applicable Law;
        </p>
        <p>
          3.2.5 You will promptly notify the Company if any of the conditions
          outlined in Sections 3.2.1 - 3.2.4 above are no longer met.
        </p>
        <p className="underline my-5 font-bold">
          4. Your Use of the Start2 Account.
        </p>
        <p>
          4.1 You are solely responsible for maintaining the confidentiality of
          the identification details you use to register your Start2 account,
          and you are solely responsible for all activities occurring within
          this account. It is therefore recommended that you keep your access
          details associated with your Start2 account strictly confidential.
        </p>
        <p>
          4.2 Your Start2 account is personal and non-transferable. You may not
          share placement orders with any other person or allow anyone else to
          use your Start2 account.
        </p>
        <p className="underline my-5 font-bold">5. Placement Services.</p>
        <p>
          5.1 The Application serves as a means enabling Customers requesting
          professional services (whether urgent, one-time, or otherwise) to
          connect with Service Providers and vice versa, based on the
          geographical location of the Customers and Service Providers and the
          availability of Service Providers. The Company does not provide
          dishwashing and/or cleaning services and is not an agent or partner of
          the Customers or the Service Providers. The Company is a provider of
          technological services using an electronic platform to offer the
          Placement Services.
        </p>
        <p>
          5.2 Customers are required to download and install the Application on
          their mobile devices and will be responsible for any costs associated
          with such a device, including (but not limited to) data or call usage
          fees.
        </p>
        <p>
          5.3 As part of the placement order, Customers will specify the time
          for the Service Provider to arrive at the Customer's premises and the
          estimated completion time for the professional services (the time
          between arrival and completion will be referred as "Shift").
        </p>
        <p>
          5.4 Customers may use the Application to place placement orders at any
          time, reject approval of Service Providers for placement orders within
          10 (ten) minutes of receiving such approval, are not required to
          submit a minimum number of placement orders, and are also not limited
          in the maximum number of placement orders they can submit. Customers
          must ensure they comply with all regulatory requirements imposed on
          them as recipients of professional services.
        </p>
        <p>
          5.5 Notwithstanding Section 5.1, the Company does not guarantee the
          availability of Service Providers and does not ensure uninterrupted or
          error-free use of the Application. The Company, at its sole
          discretion, may prioritize placement orders and/or Customers as it
          sees fit. The Company will not be liable for any damages, losses,
          claims, costs, or expenses arising from or resulting from planned or
          unplanned downtime, unavailability, or slowness.
        </p>
        <p>
          5.6 Nothing in these terms shall be construed as creating any
          employee-employer relationship between the Company and the Customer.
          The Company will not be liable to the Customer, and the Customer
          hereby irrevocably waives any claim against the Company and/or its
          directors, officers, and/or employees regarding professional services
          provided by Service Providers or any acts or omissions of Service
          Providers.
        </p>
        <p>
          5.7 This Agreement does not create any agency relationship between the
          Customer and the Company. Neither party shall have any right or
          authority to bind and/or obligate the other party in any way with
          respect to third parties, unless explicitly authorized in writing.
        </p>
        <p>
          5.8 You are solely responsible for your engagements with Service
          Providers. It is clarified that Service Providers are third-party
          employees and not employees of the Company, and that the Company does
          not conduct any background checks on Service Providers or make any
          representations regarding Service Providers.
        </p>
        <p className="underline my-5 font-bold">
          6. Penalties and Additional Charges
        </p>
        <p>
          6.1 Canceling a shift less than two hours before the shift: the worker
          will be compensated for one hour of work.
        </p>
        <p>
          6.2 Canceling less than half an hour before the shift start time: the
          worker will be compensated for 2 hours of work.
        </p>
        <p>
          6.3 If the shift is less than 4 hours, the worker will be paid for a
          minimum of 4 hours as if they worked for that time.
        </p>
        <p className="underline my-5 font-bold">7. Professional Services.</p>
        <p>
          7.1 The Customer undertakes to treat the Service Providers in a
          proper, professional, and lawful manner and to ensure a safe and
          appropriate work environment for the performance of the professional
          services by the Service Providers.
        </p>
        <p>
          7.2 You shall indemnify the Company against all liabilities, costs,
          expenses, damages, and losses (including but not limited to direct,
          indirect, or consequential damages, loss of profit, loss of goodwill,
          and all interest, penalties, legal costs (on a full indemnity basis),
          and all other reasonable professional costs and expenses) arising out
          of or in connection with your breach of any of your representations
          and commitments under this Agreement.
        </p>
        <p className="underline my-5 font-bold">8. Consideration.</p>
        <p>
          8.1 In consideration for the professional services, you shall be
          charged: (1) a fixed monthly membership fee of 200 NIS (this amount
          may be updated by the Company from time to time with prior written
          notice), which shall be paid on the 1st day of each month
          (collectively: "Membership Fee"); and (2) an amount calculated based
          on the shift time (collectively: "Consideration"). After completing
          the professional services and making the payment (collectively:
          "Completed Order"), the Company will send you an electronic receipt.
          Details of previously completed orders will be available on the
          Application.
        </p>
        <p>
          8.2 If, before or during the shift, you require the Service Providers
          to extend or shorten the duration of the shift (and such extension is
          permitted by law), the Consideration will be recalculated to reflect
          such changes based on the time added/reduced. Changes to the
          Consideration resulting from the extension/reduction of the shift
          duration will be reported to you via the Application.
        </p>
        <p>
          8.3 By placing a placement order, you agree that the Company may block
          your provided credit card for an amount equal to the Consideration for
          the requested shift (collectively: "Pre-approved Payment"). Such an
          amount will not be charged from your account when placing the
          placement order but will be allocated for payment of the
          Consideration, as detailed in Sections 7.1 and 7.2 above. If, at the
          end of the shift, full payment of the allocated Consideration is not
          successfully made by you ("Outstanding Consideration"), the
          Pre-approved Payment may be used to fully or partially settle the
          Outstanding Consideration. If the payment is fully received, the
          Pre-approved Payment will be released by the Company.
        </p>
        <p>
          8.4 If you fail to pay the full Consideration or part thereof, the
          Company reserves the right to continue attempting to charge your
          registered credit card for any unpaid Consideration(s) until full
          payment is made. The Company may suspend or terminate your use of the
          Application as it deems appropriate. The Company reserves all rights
          under applicable law to collect the Consideration and any incurred
          costs and expenses, including reasonable attorney's fees, resulting
          from the Company's efforts to collect the Consideration.
        </p>
        <p className="underline my-5 font-bold">9. Termination of Agreement.</p>
        <p>
          9.1 You may close your Start2 account at any time and for any reason.
        </p>
        <p>
          9.2 The Company may suspend or close your Start2 account without prior
          notice and at its sole discretion: (a) if instructed to do so by a
          competent authority; (b) based on legal advice provided to the
          Company; (c) based on complaints and/or reports from Service
          Providers; (d) if you fail to pay the full Consideration or part
          thereof; or (e) if the Company believes you have breached this
          Agreement, Applicable Law, or the Privacy Policy.
        </p>
        <p>
          9.3 Without prejudice to the above, the Company may suspend or
          terminate its Placement Services for any reason and at its sole
          discretion by providing notice to all users.
        </p>
        <p>
          9.4 Upon closing your account, this Agreement will also terminate.
          However, the provisions of Sections 5.5-5.7, 6.4, 7.4, 8.4, and 10-16
          will continue to apply even after the termination of this Agreement.
        </p>
        <p className="underline my-5 font-bold">
          10. Customer Guidelines for Permitted Activities.
        </p>
        <p>
          10.1 As a registered user of Start2, and subject to these terms, you
          are permitted to access and use the Placement Services. All use of the
          Placement Services shall solely be for the purpose of engaging and
          receiving professional services, and you agree not to use the
          Placement Services and/or any content contained therein for any other
          purpose. Furthermore, you commit to:
        </p>
        <p>
          10.1.1 Not copying, modifying, transmitting, creating derivative works
          from, reproducing, or making use of any copyrighted material,
          including (but not limited to), images, trademarks, trade names, trade
          marks, or other intellectual property, proprietary content, or
          information accessible through the Placement Services without prior
          written consent from the Company.
        </p>
        <p>
          10.1.2 Not using the Placement Services in any manner that could
          interfere with, disrupt, or negatively affect the Placement Services
          or the servers or networks connected to the Placement Services.
        </p>
        <p>
          10.1.3 Not uploading viruses or other malicious code or otherwise
          compromising the security of the Placement Services or any related
          systems.
        </p>
        <p>
          10.1.4 Not modifying, adapting, sublicensing, translating, selling,
          reverse engineering, decompiling, or disassembling any part of the
          Placement Services or causing others to do so.
        </p>
        <p>
          10.1.5 Not accessing or using the Placement Services or professional
          services without authorization or publishing any professional services
          without the Company's written consent.
        </p>
        <p>
          10.1.6 Not probing, scanning, or testing the vulnerability of the
          Application or any related systems or networks.
        </p>
        <p>
          10.1.7 Not encouraging or promoting any activity that violates this
          Agreement.
        </p>
        <p>
          10.1.8 Not using the Placement Services in a manner that could
          negatively impact the Company's reputation.
        </p>
        <p>
          10.1.9 Not directly contacting Service Providers or employing them
          without the Company's mediation, nor sharing any of their details with
          third parties.
        </p>
        <p>
          In the event of a breach of Section 10.1.9, you agree to pay the
          Company agreed-upon damages equal to the greater of: (1) two months'
          gross salary paid by you to the relevant Service Provider (if paid);
          or (2) an amount equivalent to two months of professional services
          provided by the relevant Service Provider (based on a full-time rate
          as listed on the platform at the time). By agreeing to this Agreement,
          you acknowledge that these agreed-upon damages represent a reasonable
          estimate of the damages the Company may suffer as a result of your
          breach of Section 10.1.9.
        </p>
        <p>
          10.2 The Company may investigate and take any legal action available
          to it in response to unauthorized and/or unlawful use of the Placement
          Services or any violation of this Agreement, including (but not
          limited to) closing your Start2 account.
        </p>
        <p>
          10.3 Without limiting the generality of the foregoing, with respect to
          both Customers and Service Providers, you agree:
        </p>
        <p>
          10.3.1 Not to use the Placement Services in any unlawful manner or in
          violation of this Agreement.
        </p>
        <p>10.3.2 Not to use the Placement Services for any harmful purpose.</p>
        <p>10.3.3 Not to impersonate any person or entity.</p>
        <p>
          10.3.4 Not to solicit, obtain, transfer, or use passwords or personal
          identifying information of other users for any purpose or to disclose
          another person's personal information without their permission.
        </p>
        <p>
          10.3.5 Not to use another user's account, share an account with
          another user, or maintain more than one account.
        </p>
        <p>
          10.3.6 Not to create another account if the Company has already closed
          your account, unless you have the Company's prior written consent.
        </p>
        <p className="underline my-5 font-bold">
          11. Company's Rights Under the Agreement.
        </p>
        <p>
          By creating a Start2 account, you hereby grant the Company an
          irrevocable license to use any information you provide during your use
          of the Placement Services, subject to the Company's Privacy Policy (as
          may be updated from time to time).
        </p>
        <p className="underline my-5 font-bold">12. Disclaimer.</p>
        <p>
          The Placement Services are provided "as is" and "as available." Except
          as required by law, the Company makes no warranties, representations,
          or guarantees of any kind, express or implied, statutory or otherwise,
          regarding the Placement Services (including any content contained
          therein). The Company does not guarantee or promise that:
        </p>
        <p>
          12.1 The Placement Services will be uninterrupted, secure, or
          error-free;
        </p>
        <p>12.2 Any defects or errors will be corrected; or</p>
        <p>
          12.3 The information obtained through the Placement Services is
          accurate or reliable.
        </p>
        <p>
          You acknowledge that any material downloaded or otherwise obtained
          through the use of the Placement Services is done at your own
          discretion and risk.
        </p>
        <p className="underline my-5 font-bold">13. Limitation of Liability.</p>
        <p>
          Except as required by law, in no event shall the Company, its
          affiliates, or employees be liable for any indirect, consequential,
          punitive, incidental, exemplary, or special damages (including, but
          not limited to, lost profits, data, use, or goodwill) arising out of
          or related to your use or inability to use the Placement Services or
          your interactions with other users. In any case, the Company's
          aggregate liability to you shall not exceed 100 NIS.
        </p>
        <p className="underline my-5 font-bold">14. Force Majeure.</p>
        <p>
          The Company shall not be liable for any failure or delay in performing
          its obligations under this Agreement due to circumstances beyond its
          control, including but not limited to natural disasters, acts of God,
          war, terrorism, strikes, or government actions.
        </p>
        <p className="underline my-5 font-bold">
          15. Governing Law and Jurisdiction.
        </p>
        <p>
          This Agreement shall be governed by the laws of the State of Israel,
          without regard to its conflict of law principles. Any disputes arising
          from or related to this Agreement shall be subject to the exclusive
          jurisdiction of the courts in Tel Aviv, Israel.
        </p>
        <p className="underline my-5 font-bold">16. Severability.</p>
        <p>
          If any provision of this Agreement is found to be invalid or
          unenforceable by a court of competent jurisdiction, the remaining
          provisions shall remain in full force and effect.
        </p>
        <p className="underline my-5 font-bold">17. Indemnification.</p>
        <p>
          To the fullest extent permitted by law, you agree to indemnify,
          defend, and hold harmless the Company, its affiliates, officers,
          directors, agents, and employees from and against any claims,
          liabilities, damages, losses, costs, and expenses (including
          reasonable attorney's fees) arising out of or related to your access
          to or use of the Placement Services, your account, or your violation
          of this Agreement.
        </p>
        <p className="underline my-5 font-bold">18. Entire Agreement.</p>
        <p>
          This Agreement and the Privacy Policy constitute the entire agreement
          between you and the Company regarding the use of the Placement
          Services and supersede any prior agreements or understandings.
        </p>
        <div></div>
      </div>
    </div>
  );
}
