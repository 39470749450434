import React from 'react'

export function TermsOfUseCustomersHeb() {
    

    return (
        <div className='mb-36 mx-auto ' style={{maxWidth:'700px',paddingLeft:'16px',paddingRight:'16px'}}>
            <div className='mt-10'>
        <h1 className='text-2xl sm:text:3xl text-center mb-6'>תנאי שימוש</h1>
        <p className=' font-bold'>אנא קרא* בעיון את התנאים ואת ההגבלות הללו (&quot;ההסכם&quot;) לפני יצירת חשבון WeWash ו/או</p>
        <p  className='font-bold'>השימוש בשירותי השמה.</p>
<p className='underline my-5 font-bold'>מבוא.</p>
<p>הסכם זה מסדיר את כל השימושים (1) באתר [WeWash] [www.start2.co.il] (להלן:</p>
<p>&quot;האתר&quot;), הנמצא בבעלות ווי וואש טכנולוגיות בע&quot;מ, חברה הממוקמת ברח&apos; הגרא 34, תל</p>
<p>אביב, ישראל, והמאוגדת כדין לפי חוקי מדינת ישראל (להלן: &quot;החברה&quot; או &quot;Start2&quot;); (2)</p>
<p>האפליקציה/ות של החברה למכשירים ניידים שעליהן חל הסכם זה (להלן: &quot;האפליקציה&quot;); ו/או</p>
<p>(3) כל אחד מהשירותים המוצעים על ידי החברה הנגישים דרך האתר ו/או האפליקציה.</p>
<p className='underline my-5 font-bold'>1. הגדרות.</p>
<p>&quot;האפליקציה&quot; כהגדרתה במבוא להסכם זה.</p>
<p>&quot;הדין החל&quot; פירושו כל החוקים והתקנות החלים בישראל.</p>
<p>&quot;הזמנת השמה&quot; משמעה בקשה לשירותים מקצועיים שנשלחה על ידי הלקוח באמצעות</p>
<p>האפליקציה.</p>
<p>&quot;חשבון לקוח&quot; משמעו חשבון הלקוח בחברה המכיל את המידע האישי של הלקוח</p>
<p>לרבות שם מלא, כתובת, כתובת דואר אלקטרוני, מספר טלפון, פרטי תשלום, פרטי</p>
<p>כרטיס אשראי, הצעות קודמות של הזמנות השמה, דיווחי שעות של שירותים מקצועיים</p>
<p>שבוצעו בפועל, משובם של ספקי שירותים (?), ו&hellip;&hellip;&hellip;. (להלן ביחד: &quot;המידע האישי&quot;).</p>
<p>&quot;לקוח&quot; או &quot;אתה&quot; משמעם המשתמש בשירותי ההשמה (מסעדות, מועדונים, בתי קפה</p>
<p>ו/או כל מוסד אחר המגיש מזון ו/או משקאות, המשתמש באפליקציה והמעוניין בקבלת</p>
<p>שירותים מקצועיים מספקי השירות).</p>
<p>&quot;מדיניות הפרטיות&quot; משמעתה מדיניות הפרטיות של החברה, המצויה באתר ו/או</p>
<p>באפליקציה. מדיניות הפרטיות עשויה להיות מתוקנת ו/או מעודכנת על ידי החברה מעת</p>
<p>לעת.</p>
<p>&quot;ספקי השירותים&quot; משמעם כל אדם המשתמש באפליקציה על מנת לקבל הזמנות</p>
<p>השמה לצורך ביצוע שירותים מקצועיים דרך האפליקציה וכדי לקבל, לפי שיקול דעתו, כל</p>
<p>או חלק מהזמנות ההשמה כאמור (או אף אחת מהן) - ולבצע את אותם שירותים</p>
<p>מקצועיים, בהתאם לתנאי ההצעות שהתקבלו על ידו באמצעות האפליקציה.</p>
<p>&quot;שירותי השמה&quot; משמעם הפלטפורמה, באמצעות האפליקציה או בכל אופן אחר,</p>
<p>המחברת בין לקוחות לבין ספקי השירות לצורך ביצוע שירותים מקצועיים בהתאם</p>
<p>להזמנות ההשמה המתאימות.</p>
<p>&quot;שירותים מקצועיים&quot; פירושם שירותי שטיפת כלים (ו/או כל שירותים כלליים אחרים</p>
<p>הקשורים לפעילות במטבח) הניתנים על ידי ספקי שירות ללקוחות בהתאם להזמנות</p>
<p>ההשמה המתאימות.</p>
<p className='underline my-5 font-bold'>2. התקשרות בהסכם זה וקבלת מדיניות הפרטיות.</p>
<p>על ידי שימוש ו/או רישום לאפליקציה, לרבות (אך לא רק) יצירת חשבון משתמש Start2</p>
<p>(כפי שמוגדר לעיל), בין אם באמצעות מכשיר נייד, אפליקציה במכשיר נייד או מחשב,</p>
<p>אתה מסכים ומסכים מרצון להיות מחויב ל: (1) הסכם זה; וכן (2) מדיניות הפרטיות של</p>
<p>החברה.</p>
<p>אם אינך מקבל, מסכים או מעוניין בכל דרך להיות מחויב להסכם זה ו/או למדיניות</p>
<p>הפרטיות, אל תיצור חשבון משתמש ו/או תשתמש באפליקציה ו/או בשירותי</p>
<p>ההשמה.</p>
<p>החברה רשאית לשנות ולעדכן הסכם זה מעת לעת. הגרסה המעודכנת ביותר של הסכם</p>
<p>זה היא זו הזמינה באתר/אפליקציה. המשך השימוש שלך באתר, באפליקציה או</p>
<p>בשירותי ההשמה ייחשב כהסכמה שלך לגרסה העדכנית ביותר של הסכם זה.</p>
<p className='underline my-5 font-bold'>3. זכאות.</p>
<p>3.1 עליך להיות מעל גיל 18, על מנת להשתמש בשירותי ההשמה, וכדי לפתוח</p>
<p>ולהחזיק חשבון לקוח.</p>
<p>3.2 על ידי השימוש באפליקציה ובשירותי ההשמה, אתה מצהיר ומתחייב בזאת כי:</p>
<p>3.2.1 יש לך את הזכות, הסמכות והיכולת להתקשר בהסכם זה ולקיים את כל</p>
<p>תנאי השימוש וההגבלות בהסכם זה.</p>
<p>3.2.2 אתה תציית לכל החוקים, הכללים והתקנות בקשר עם השימוש שלך</p>
<p>בשירותי ההשמה ו\או בכל שירות אחר המוסדר ע&quot;י שירותי ההשמה.</p>
<p>3.2.3 קראת בעיון את הסכם זה ואת מדיניות הפרטיות והבנת את</p>
<p>הוראותיהם.</p>
<p>3.2.4 אתה תציית, בכל עת, להוראות הסכם זה, למדיניות הפרטיות ולדין</p>
<p>החל;</p>
<p>3.2.5 אתה תודיע מיידית לחברה אם אחד מהתנאים המפורטים בסעיפים</p>
<p>3.2.1 -3.2.4 לעיל אינו מתקיים יותר.</p>
<p className='underline my-5 font-bold'>4. שימושך בחשבון Start2.</p>
<p>4.1 אתה האחראי הבלעדי לשמירה על פרטי ההזדהות שבהם אתה משתמש כדי</p>
<p>להירשם לחשבון ה- Start2שלך, ואתה האחראי הבלעדי לכל הפעילויות</p>
<p>המתרחשות במסגרת חשבון זה. לכן, מומלץ לשמור את נתוני הגישה שלך</p>
<p>הקשורים לחשבון ה- Start2שלך בסודיות מוחלטת.</p>
<p>4.2 חשבון ה- Start2שלך הוא אישי ואינו ניתן להעברה. אינך רשאי לשתף הזמנות</p>
<p>השמה עם כל אדם אחר ואינך רשאי לאפשר לאף אדם אחר להשתמש בחשבון ה-</p>
<p>Start2שלך.</p>
<p className='underline my-5 font-bold'>5. שירותי ההשמה.</p>
<p>5.1 האפליקציה מהווה אמצעי המאפשר ללקוחות המבקשים שירותים מקצועיים (בין</p>
<p>אם באופן דחוף, חד פעמי או אחר) להיות מחוברים לספקי השירותים ולהיפך -</p>
<p>בהתבסס על המיקום הגיאוגרפי של הלקוחות ושל ספקי השירותים, והזמינות של</p>
<p>ספקי השירותים. החברה אינה מספקת שירותי שטיפת כלים ו/או ניקיון ואינה</p>
<p>שלוח או שותף של הלקוחות, ושלוח, שותף ו/או מעסיק של ספקי השירותים.</p>
<p>החברה הינה ספקית שירותים טכנולוגיים המשתמשת בפלטפורמה אלקטרונית</p>
<p>על מנת לספק את שירותי ההשמה.</p>
<p>5.2 הלקוחות נדרשים להוריד ולהתקין את האפליקציה במכשיר הנייד שלהם והם יהיו</p>
<p>אחראים לכל העלויות הכרוכות במכשיר נייד זה, לרבות (אך לא רק), כל דמי</p>
<p>שימוש בנתונים או שיחות.</p>
<p>5.3 במסגרת הזמנת ההשמה יפרטו הלקוחות את זמן הגעת ספק השירותים למתחם</p>
<p>הלקוח ואת המועד המשוער להשלמת השירותים המקצועיים (פרק הזמן שבין</p>
<p>הגעה לסיום יקרא להלן: &quot;משמרת&quot;).</p>
<p>5.4 לקוחות רשאים להשתמש באפליקציה, להגיש הזמנות השמה בכל עת, לדחות</p>
<p>אישור של ספקי שירות להזמנות השמה תוך 10 (עשר) דקות מרגע קבלת אישור</p>
<p>כאמור, הלקוח אינו נדרש להגיש מספר מינימלי של הזמנות השמה ואין גם</p>
<p>הגבלה על המספר המירבי של הזמנות השמה שניתן להגיש. על הלקוחות לוודא</p>
<p>כי הם מצייתים לכל דרישות רגולטוריות המוטלות עליהם כמקבלי שירותים</p>
<p>מקצועיים.</p>
<p>5.5 על אף האמור בסעיף 5.1, החברה אינה מתחייבת לזמינותם של ספקי שירותים,</p>
<p>ואינה מבטיחה זמינות ו/או שימוש ללא הפרעות או שגיאות באפליקציה. החברה,</p>
<p>לפי שיקול דעתה הבלעדי, רשאית לתעדף הזמנות השמה ו/או לקוחות כפי</p>
<p>שתראה לנכון. החברה לא תישא באחריות לכל נזק, הפסד, תביעות, עלויות או</p>
<p>הוצאות הנובעות או כתוצאה מזמן השבתה מתוכנן או לא מתוכנן, חוסר זמינות או</p>
<p>איטיות.</p>
<p>5.6 שום דבר הכלול בתנאים אלה לא יתפרש כיוצר מערכת יחסים כלשהי של עובד-</p>
<p>מעסיק בין החברה ללקוח. כמו כן, החברה לא תישא באחריות כלפי הלקוח,</p>
<p>והלקוח מוותר בזאת באופן בלתי הפיך על כל טענה כלפי החברה ו/או</p>
<p>הדירקטורים, נושאי המשרה ו/או העובדים שלה בקשר עם מתן שירותים</p>
<p>מקצועיים על ידי ספקי השירותים, ו/או עם מעשים או מחדלים של ספקי</p>
<p>השירותים.</p>
<p>5.7 הסכם זה אינו יוצר יחסי שולח-שלוח בין הלקוח לבין החברה. לאף אחד מהצדדים</p>
<p>לא תהיה כל זכות או סמכות כלשהי להתקשר ו/או לחייב את הצד השני בכל צורה</p>
<p>שהיא ביחס לצדדים שלישיים, אלא אם הוסמך לכך במפורש בכתב.</p>
<p>5.8 אתה האחראי הבלעדי להתקשרויות שלך עם ספקי השירותים. מובהר שספקי</p>
<p>השירותים הם עובדים של צד שלישי ואינם עובדי החברה, ושהחברה אינה</p>
<p>מבצעת בדיקות רקע כלשהן של ספקי השירותים, ואינה מציגה מצגים כלשהם</p>
<p>אודות ספקי השירותים.</p>
<p className='underline my-5 font-bold'>6. קנסות וחיובים נוספים</p>
<p>6.1 ביטול של משמרת פחות משעתיים לפני המשמרת העובד יקבל פיצוי של שעת</p>
<p>עבודה.</p>
<p>6.2 פחות מחצי שעה ועד מועד תחילת המשמר העובד יקבל פיצוי של 2 שעות עבודה</p>
<p>6.3 משמרת העובד היא מינימום 4 שעות, כל ביטול של פחות מ 4 שעות מתחילת</p>
<p>העבודה יקבל העובד שכר כאילו עבד 4 שעות</p>
<p className='underline my-5 font-bold'>7. השירותים המקצועיים.</p>
<p>7.1 הלקוח מתחייב להתייחס לספקי השירותים בצורה הולמת, מקצועית וחוקית.</p>
<p>ולהבטיח סביבת עבודה בטוחה והולמת לצורך ביצוע השירותים המקצועיים ע&quot;י</p>
<p>ספקי השירותים.</p>
<p>7.2 אתה תשפה את החברה מפני כל חבויות, עלויות, הוצאות, נזקים והפסדים</p>
<p>(לרבות אך לא רק נזקים ישירים, עקיפים או תוצאתיים, אובדן רווח, אובדן מוניטין</p>
<p>וכל ריבית, קנסות, עלויות משפטיות (על בסיס שיפוי מלא) וכל שאר העלויות</p>
<p>וההוצאות המקצועיות) שנגרמו ו/או שהחברה נשאה בהן כתוצאה מהפרה על ידך</p>
<p>של איזה מהמצגים וההתחייבויות שלך במסגרת הסכם זה.</p>
<p className='underline my-5 font-bold'>8. תמורה.</p>
<p>8.1 בתמורה לשירותים המקצועיים תחויב: (1) בדמי שימוש חודשיים קבועים בסך</p>
<p>_200ש&quot;ח_ (סכום זה עשוי להתעדכן על ידי החברה מעת לעת בהודעה מראש</p>
<p>ובכתב) אשר ישולם ביום _1_ לכל חודש (להלן: &quot;דמי החברות&quot;); וכן (2) בסכום</p>
<p>שחושב על בסיס זמן המשמרת (להלן: &quot;התמורה&quot;). לאחר השלמת השירותים</p>
<p>המקצועיים וביצוע התשלום (להלן: &quot;הזמנה שהושלמה&quot;), החברה תשלח לך</p>
<p>קבלה אלקטרונית. פרטים על הזמנות שהושלמו בעבר יהיו זמינים באפליקציה.</p>
<p>8.2 אם, לפני או במהלך המשמרת, תדרוש מספקי השירותים להאריך או להפחית את</p>
<p>משך זמן המשמרת (והארכה כזו מותרת על פי חוק), התמורה תחושב מחדש</p>
<p>ותשקף את השינויים כאמור, בהתאם לזמן שנוסף\שהופחת, שינויים בתמורה</p>
<p>הנובעים מהארכת\הפחתת זמן המשמרת ידווחו לך באמצעות האפליקציה.</p>
<p>8.3 עם ביצוע הזמנת השמה, אתה מסכים לכך שהחברה תבצע חסימה בכרטיס</p>
<p>האשראי שלך שסופק על ידך בגובה התמורה עבור המשמרת המבוקשת (להלן:</p>
<p>&quot;התשלום המאושר מראש&quot;). סכום כזה לא יחויב מחשבונך בעת ביצוע הזמנת</p>
<p>ההשמה, אך יופרש לתשלום התמורה, כמפורט בסעיפים 7.1 ו-7.2 לעיל. אם,</p>
<p>בתום המשמרת, תשלום מלא של התמורה שהופרשה לא בוצע על ידך בהצלחה</p>
<p>(&quot;התמורה שטרם שולמה&quot;), התשלום המאושר מראש עשוי לשמש לפירעון מלא</p>
<p>או חלקי, במלואו או בחלקו, לתשלום התמורה שטרם שולמה. אם התשלום יתקבל</p>
<p>במלואו, התשלום המאושר מראש ישוחרר על ידי החברה.</p>
<p>8.4 אם לא תשלם את מלוא התמורה או חלק ממנה, החברה שומרת על זכותה</p>
<p>להמשיך ולנסות לחייב את כרטיס האשראי שלך הרשום בחשבונך עבור כל</p>
<p>תמורה(ות) אשר לא שולמו עד תשלומם המלא, והיא רשאית להשעות או להפסיק</p>
<p>את השימוש שלך באפליקציה, כפי שתמצא לנכון. החברה שומרת לעצמה את כל</p>
<p>הזכויות הקיימות על פי דין לגבות את התמורה ואת כל העלויות וההוצאות</p>
<p>שנגרמו, לרבות שכר טרחה סביר של עורכי דין, שנגרמו לחברה כתוצאה מניסיונה</p>
<p>לגבות את התמורה.</p>
<p className='underline my-5 font-bold'>9. סיום ההסכם.</p>
<p>9.1 אתה רשאי לסגור את חשבונך ב- Start2, מכל סיבה שהיא, ובכל עת.</p>
<p>9.2 החברה רשאית להשעות או לסגור את חשבון ה- Start2 שלך, ללא הודעה מראש</p>
<p>ועל פי שיקול דעתה הבלעדי: (א) אם הורו לעשות כן על ידי רשות מוסמכת; (ב)</p>
<p>בהתאם לייעוץ משפטי שניתן לה לעשות כן על ידי היועץ המשפטי שלה; (ג)</p>
<p>בהתבסס על תלונות ו/או דיווחים של ספקי שירותים; (ד) אם לא תשלם את מלוא</p>
<p>התמורה או חלק ממנה; או (ה) אם היא סבורה שהפרת הסכם זה, את הדין החל</p>
<p>או את מדיניות הפרטיות.</p>
<p>9.3 מבלי לגרוע מהאמור לעיל, החברה רשאית להשעות או להפסיק את שירותי</p>
<p>ההשמה שלה, מכל סיבה ולפי שיקול דעתה הבלעדי, על ידי מתן הודעה לכל</p>
<p>המשתמשים.</p>
<p>9.4 לאחר סגירת חשבונך, הסכם זה יסתיים גם הוא. יחד עם זאת, הוראות סעיפים</p>
<p>5.7-5.5, 6.4, 7.4, 8.4 ו-16-10 ימשיכו לחול גם לאחר סיום ההסכם.</p>
<p className='underline my-5 font-bold'>10. הנחיות לגבי פעילויות מורשות של הלקוח.</p>
<p>10.1 כמשתמש רשום ב- Start2, ובכפוף לתנאים אלו, תורשה לגשת לשירותי ההשמה</p>
<p>ולהשתמש בהם. כל שימוש בשירותי ההשמה ישמש רק למטרת התקשרות</p>
<p>וקבלת שירותים מקצועיים, ואתה מסכים לא להשתמש בשירותי ההשמה ו/או בכל</p>
<p>תוכן הכלול בתוכם, לכל מטרה אחרת. יתר על כן, אתה מתחייב:</p>
<p>10.1.1 לא להעתיק, לשנות, לשדר, לייצר עבודה נגזרת, לשכפל או לעשות</p>
<p>שימוש בכל חומר המוגן בזכויות יוצרים, לרבות (אך לא רק), תמונות,</p>
<p>סימנים מסחריים, שמות מסחריים, סימני מסחר או קניין רוחני אחר, תוכן</p>
<p>או מידע קנייני הנגיש דרך שירותי ההשמה, ללא הסכמה מראש ובכתב</p>
<p>של החברה.</p>
<p>10.1.2 לא להשתמש בשירותי ההשמה בכל דרך העלולה להפריע, לשבש או</p>
<p>להשפיע לרעה על שירותי ההשמה או השרתים או הרשתות המחוברות</p>
<p>לשירותי ההשמה.</p>
<p>10.1.3 לא להעלות וירוסים או קוד זדוני אחר או לגרום לכל נזק לחברה ו/או</p>
<p>ליכולתה של החברה לספק את שירותי ההשמה או לאבטחת שירותי</p>
<p>ההשמה.</p>
<p>10.1.4 לא לשנות, להתאים, לתת רישיון משנה, לתרגם, למכור, לבצע &quot;הנדסה</p>
<p>הפוכה&quot; (reverse engineering), לפענח ו\או לפרק כל חלק משירותי</p>
<p>ההשמה, או לגרום לאחרים לעשות זאת.</p>
<p>10.1.5 לא להיכנס ו/או להשתמש בשירותי ההשמה ו/או בשירותים המקצועיים</p>
<p>ללא הרשאה או לפרסם כל אחד מהשירותים המקצועיים ללא הסכמה</p>
<p>בכתב של החברה.</p>
<p>10.1.6 לא לחדור ו/או לסרוק ו/או לבדוק את פגיעות וחולשות האפליקציה או כל</p>
<p>מערכת או רשת אחרת.</p>
<p>10.1.7 לא לעודד או לקדם כל פעילות המפרה הסכם זה.</p>
<p>10.1.8 לא להשתמש בשירותי ההשמה באופן שעלול להשפיע לרעה על</p>
<p>המוניטין של החברה.</p>
<p>10.1.9 לא ליצור קשר ישיר עם ספקי השירותים ו/או להעסיק את ספקי השירות</p>
<p>במישרין ו/או לא להעביר פרט מפרטיהם לצדדים שלישיים.</p>
<p>מבלי לגרוע מכל סעד העומד לרשות החברה לפי הסכם זה ו\או כל דין,</p>
<p>אתה מסכים שבמקרה של הפרה של סעיף 9.1.9 זה, אתה תשלם</p>
<p>לחברה פיצויים מוסכמים בסכום השווה לגבוה מבין (1) שתי משכורות</p>
<p>חודשיות ברוטו ששולמו על ידך לספק שירותים הרלוונטי (במידה</p>
<p>ושולמו); או (2) סכום שווה ערך לתשלומים המגיעים לספק השירותים</p>
<p>הרלוונטי עבור חודשיים של שירותים מקצועיים (על בסיס משרה מלאה)</p>
<p>- בהתבסס על התעריף העדכני ביותר של ספק שירותים זה (כמפורט</p>
<p>בפלטפורמה באותו זמן). בהסכמתך להסכם זה אתה מסכים שפיצויים</p>
<p>מוסכמים כאמור משקפים אומדן סביר של הנזקים שעלולים להיגרם</p>
<p>לחברה אם תפר את הוראות סעיף 9.1.9.</p>
<p>10.2 החברה רשאית לחקור ולנקוט כל פעולה משפטית העומדת לרשותה בתגובה</p>
<p>לשימוש בלתי חוקי ו/או לא מורשה בשירותי ההשמה, או כל הפרה של הסכם זה,</p>
<p>לרבות (אך לא רק), סגירת חשבון ה- Start2שלך.</p>
<p>10.3 מבלי לגרוע מכלליות האמור לעיל וביחס ללקוחות ולספקי השירות, בעת השימוש</p>
<p>בשירותי ההשמה, אתה מסכים:</p>
<p>10.3.1 לא להשתמש בשירותי ההשמה בכל דרך שהיא בלתי חוקית או סותרת</p>
<p>את הוראות הסכם זה.</p>
<p>10.3.2 לא להשתמש בשירותי ההשמה לכל מטרה מזיקה.</p>
<p>10.3.3 לא להתחזות לכל אדם או ישות.</p>
<p>10.3.4 לא לבקש, להשיג, להעביר ולהשתמש בסיסמאות או מידע מזהה אישי</p>
<p>השייך למשתמשים אחרים לכל מטרה, או להפיץ מידע אישי של אחר</p>
<p>ללא רשות.</p>
<p>10.3.5 לא להשתמש בחשבון של משתמש אחר, לחלוק חשבון עם משתמש</p>
<p>אחר, או לתחזק יותר מחשבון אחד.</p>
<p>10.3.6 לא ליצור חשבון נוסף אם החברה כבר סגרה את חשבונך, אלא אם</p>
<p>קיבלת אישור מראש ובכתב של החברה.</p>
<p className='underline my-5 font-bold'>11. זכויות החברה על פי ההסכם.</p>
<p>על ידי יצירת חשבון Start2אתה מעניק בזאת לחברה רישיון בלתי חוזר להשתמש בכל</p>
<p>מידע שנמסר לה על ידך במהלך השימוש בשירותי ההשמה, בכפוף לתנאי מדיניות</p>
<p>הפרטיות של החברה (כפי שתעודכן מעת לעת).</p>
<p className='underline my-5 font-bold'>12. פטור.</p>
<p>החברה מספקת את שירותי ההשמה &quot;כפי שהם&quot; ו&quot;על בסיס זמינות&quot; ואלא אם כן</p>
<p>נקבע אחרת בחוק, החברה אינה מעניקה התחייבות, אחריות ו\או מצגים מכל סוג</p>
<p>שהוא, בין אם מפורש או במשתמע, סטטוטוריים או אחרים, בהתייחס לשירותי</p>
<p>ההשמה (כולל כל התוכן הכלול בהם). החברה אינה מבטיחה או מתחייבת כי (א)</p>
<p>שירותי ההשמה יהיו ללא הפרעות, מאובטחים או נטולי שגיאות, (ב) פגמים או</p>
<p>שגיאות כלשהם בשירות יתוקנו, או (ג) לגבי כל התאמה ו/או נכונות המידע שתקבל</p>
<p>באמצעות שירותי ההשמה.</p>
<p>החברה אינה לוקחת אחריות על תוצאות השימוש בשירותי ההשמה, ואינה מעניקה</p>
<p>התחייבויות ו/או מצגים באשר לתוצאות השימוש בשירותי ההשמה (לרבות, ומבלי</p>
<p>לגרוע מכלליות האמור השירותים המקצועיים). כל חומר שהורד או הושג בדרך</p>
<p>אחרת באמצעות השימוש בשירותי ההשמה נעשה על אחריותך ועל שיקול דעתך.</p>
<p className='underline my-5 font-bold'>13. הגבלת אחריות.</p>
<p>אלא אם כן נקבע אחרת בחוק, בשום מקרה החברה, שותפיה ו/או עובדיה, יהיו</p>
<p>אחראים לכל נזק עקיף, תוצאתי, עונשי, אגבי, פיצויים לדוגמא ו/או ספציפי (לרבות,</p>
<p>מבלי לגרוע מכלליות האמור, אובדן רווחים), בין אם נגרמו במישרין או בעקיפין, או</p>
<p>כל אובדן נתונים, שימוש, מוניטין או הפסדים בלתי מוחשיים אחרים, הנובע מ: (א)</p>
<p>הגישה שלך או השימוש או חוסר היכולת שלך לגשת לאפליקציה ו/או להשתמש בה</p>
<p>(ב) התנהלותם של משתמשים אחרים או לקוחות כלשהם ו/או צדדים שלישיים</p>
<p>כלשהם במסגרת שירותי ההשמה, באמצעותם או בעקבות השימוש בהם, או (ג)</p>
<p>גישה או שימוש בלתי מורשים לחשבונך.</p>
<p>אלא אם כן נקבע אחרת בחוק, בשום מקרה החבות המירבית של החברה כלפיך,</p>
<p>בגין כל התביעות הקשורות לאפליקציה ו/או לשירותי ההשמה, לא תעלה על סכום</p>
<p>של 100 ש&quot;ח.</p>
<p className='underline my-5 font-bold'>14. כוח עליון.</p>
<p>בשום מקרה לא תהיה החברה אחראית לכל כשל או עיכוב בביצוע שירותי ההשמה ו/או</p>
<p>של השירותים המקצועיים ו/או בהתחייבויותיה על פי הסכם זה הנובעים או הנגרמים על</p>
<p>ידי, בין אם במישרין או בעקיפין, גורמים שמחוץ לשליטתה. לרבות אך לא רק, שביתות,</p>
<p>הפסקות עבודה, תאונות, מגיפות, מלחמה או פעולות טרור, הפרות סדר אזרחיות או</p>
<p>צבאיות, אסונות גרעיניים או אסונות טבע או מעשי אלוהים, והפרעות, אובדן או תקלות</p>
<p>של אמצעים, תקשורת או שירותים טכנולוגיים (שירותי תוכנה או חומרה); מובהר כי</p>
<p>החברה תודיע ללקוח על אירועים כאמור ותנקוט במאמצים סבירים כדי להשיב את</p>
<p>שירותי ההשמה לתיפקוד מלא בהקדם האפשרי בנסיבות העניין.</p>
<p className='underline my-5 font-bold'>15. הדין החל וסמכות השיפוט.</p>
<p>דיני מדינת ישראל, מבלי להתייחס לעקרונות של משפט בינלאומי פרטי, יחולו על כל</p>
<p>מחלוקת הנובעת מהסכם זה או הנוגעת אליו.</p>
<p>אתה מסכים במפורש שסמכות השיפוט הבלעדית לכל סכסוך הנובע או הנוגע להסכם</p>
<p>זה מצויה בבתי המשפט בתל אביב, ישראל, וכן אתה מסכים באופן מפורש להפעלת</p>
<p>סמכות השיפוט בבתי המשפט של מדינת ישראל בקשר עם כל מחלוקת כזו, לרבות כל</p>
<p>תביעה הקשורה לחברה או לחברות הבנות שלה, לעובדי החברה, קבלני החברה, נושאי</p>
<p>משרה ודירקטורים בחברה.</p>
<p className='underline my-5 font-bold'>16. תוקף ההסכם.</p>
<p>אם הוראה כלשהי בהסכם זה תימצא כלא תקפה על ידי בית משפט מוסמך, חוסר</p>
<p>התוקף של הוראה כזו לא ישפיע על תוקף שאר ההוראות של הסכם זה, אשר יישארו</p>
<p>בתוקף. שום ויתור על אחד מתנאי הסכם זה לא ייחשב כוויתור נוסף או מתמשך על תנאי</p>
<p>כזה או כל תנאי אחר.</p>
<p className='underline my-5 font-bold'>17. שיפוי.</p>
<p>בהיקף המירב המותר על פי דין, אתה מסכים לשפות, להגן ולפטור מנזק את החברה,</p>
<p>החברות הקשורות אליה, את נושאי המשרה, הדירקטורים, הסוכנים והעובדים של</p>
<p>החברה והחברות הקשורות אליה מכל תלונה, דרישה, תביעה, נזק, הפסד, עלויות,</p>
<p>התחייבויות והוצאות, לרבות שכר טרחת עורך דין סביר, הנובעים או קשורים בכל דרך</p>
<p>לגישה או לשימוש שלך באפליקציה ו/או שירותי ההשמה, חשבון ה- Start22שלך, או</p>
<p>הפרת הסכם זה על ידך.</p>
<p className='underline my-5 font-bold'>18. ההסכם המלא.</p>
<p>הסכם זה ומדיניות הפרטיות מכילים את כל ההסכמות בינך לבין החברה לגבי השימוש</p>
<p>בשירותי ההשמה.</p>
        <div >

            </div>
            
            </div>
        </div>
    )
}
